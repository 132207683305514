import { Component, OnDestroy, OnInit } from '@angular/core'
import { VideoService } from '@cheaseed/cheaseed-core'
import { Platform, ModalController } from '@ionic/angular'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'cheaseed-capture-video',
  templateUrl: './capture-video.component.html',
  styleUrls: ['./capture-video.component.scss'],
})
export class CaptureVideoComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>() // emit on destroy to unsubscribe all subscriptions created with takeUntil

  isRecording = false
  doneRecording = false
  fetchableUrl: any;
  fileUrl: any;
  interval: any;  // for elapsed timer
  elapsedSeconds = 0
  timerString = "00:00"

  constructor(
    private videoService: VideoService, 
    private platform: Platform,
    private modalController: ModalController
    ) {    
  }

  async ngOnInit() {
    console.log("initializing capture video page")
    await this.videoService.initializeFixedConfig()
    
    this.videoService.lastVideo$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data) => {
        console.log("lastvideo broadcast as", JSON.stringify(data))
        if (data) {
            const { fetchableUrl, file } = data
            this.fetchableUrl = fetchableUrl
            this.fileUrl = file
            this.isRecording = false
            this.doneRecording = true
        }
        else {
          this.isRecording = false
          this.doneRecording = false
        }
      })
  }

  async ngOnDestroy() {
    console.log("destroying capture video page")
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
    await this.videoService.destroy()
  }

  ionViewWillEnter() {
    console.log("CaptureVideoPage ionViewWillEnter")
  }

  ionViewWillLeave() {
    console.log("CaptureVideoPage ionViewWillLeave")
  }

  async toggleRecording() {
    this.isRecording = !this.isRecording
    if (this.isRecording) {
      // Count seconds
      this.elapsedSeconds = 0
      this.interval = setInterval(() => { 
        ++this.elapsedSeconds
        this.timerString = this.formatTimerString(this.elapsedSeconds)
      }, 1000)
      this.videoService.startRecording()
      this.doneRecording = false
    }
    else {
      clearInterval(this.interval)
      await this.videoService.stopRecording()
    }
  }
  
  formatTimerString(secs) {
    return (Math.floor(secs / 60) + '').padStart(2, '0') + ":" + (secs % 60 + '').padStart(2, '0')
  }

  async saveRecording() {
    await this.videoService.saveVideo()
    this.modalController.dismiss( { fetchableUrl: this.fetchableUrl })
    // window.history.go(-2)
    // this.location.back()
  }

  close() {
    this.modalController.dismiss(null)
  }

  async playRecording() {
    // this.router.navigate(['play-video'])
  }

}
