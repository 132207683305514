import { Component, Input, OnInit } from '@angular/core';
import { ChallengeSpec } from '@cheaseed/node-utils';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'cheaseed-challenge-selector',
  templateUrl: './challenge-selector.component.html',
  styleUrls: ['./challenge-selector.component.scss']
})
export class ChallengeSelectorComponent implements OnInit {

  @Input() challengeType = ''
  @Input() availables: ChallengeSpec[] = []

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() { 
  }

  cancel() {
    this.modalController.dismiss()
  }

  clickChallenge(c: ChallengeSpec) {
    this.modalController.dismiss({
      selectedChallenge: c
    })
  }

}
