import { Component } from '@angular/core';
import { SharedMessageService } from '@cheaseed/cheaseed-core';

@Component({
  selector: 'cheaseed-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
})
export class NotificationButtonComponent {
  constructor(public messageService: SharedMessageService) { }
}
