<div 
  style="display: flex; overflow-x: scroll;"
  [style.justify-content]="minWidth ? 'center' : ''">
  <ng-container *ngIf="chips | async as items">
    <ion-button 
      *ngFor="let item of items; trackBy: trackElement"
      size="small"           
      (click)="setFilterChip(item)" 
      class="{{ selected === item.type ? 'filter-button-selected' : 'filter-button-unselected' }}"
      style="font-size: 14px;"
      [style.min-width]="minWidth">
      {{ item.title || utilityService.upperFirst(item.type)  }}
    </ion-button> 
  </ng-container>
</div>