import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { EntryService, ContentService } from '@cheaseed/cheaseed-core';
import { Entry } from '@cheaseed/node-utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cheaseed-entry-selector',
  templateUrl: './entry-selector.component.html',
  styleUrls: ['./entry-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntrySelectorComponent implements OnInit {

  // Communicate via selected 
  @Input() subtype = ''
  @Input() selected: string[] = []
  @Input() buttonTitle = ''
  @Output() selectionChanged = new EventEmitter()

  selectedEntrySet: Set<Entry> = new Set()
  entryType = ''
  entryTitle = ''
  maxEntries = 1
  selectClicked$ = new BehaviorSubject<boolean>(false)
  availableEntries: Entry[] = []

  constructor(
    private entryService: EntryService,
    private contentService: ContentService  ) { }

  ngOnInit(): void {
    this.selectedEntrySet = new Set(this.selected.map(id => this.entryService.getEntryForId(id)).filter(entry => entry))
    const parts = this.subtype.split('-')
    this.entryType = parts[0]
    if (parts[1])
      this.maxEntries = parseInt(parts[1])
    this.entryTitle = this.contentService.getSingularGlobal(this.entryType)
  }

  addEntry(entry: Entry) {
    if (this.selectedEntrySet.size === this.maxEntries) {
      // Remove last entered
      const last = Array.from(this.selectedEntrySet).pop()
      this.selectedEntrySet.delete(last as Entry)
    }
    this.selectedEntrySet.add(entry)
    this.emitSelectionChanged()
  }

  removeEntry(entry: Entry) {
    this.selectedEntrySet.delete(entry)
    this.emitSelectionChanged()
  }

  emitSelectionChanged() {
    this.selectionChanged.emit( { selected: Array.from(this.selectedEntrySet).map(entry => entry.docId) } )
  }

  async clickSelect() {
    this.availableEntries = this.entryService.getEntriesOfType(this.entryType)
      .filter(entry => !this.selectedEntrySet.has(entry))
    this.selectClicked$.next(true)
  }

  dismissModal() {
    this.selectClicked$.next(false)
  }

  modalSelectionChanged(event: any) {
    this.dismissModal()
    const entry = event.selected[0]
    if (entry) {
      this.addEntry(entry)
    }
    else if (event.remindMe) {
      this.selectedEntrySet.clear()
      this.selectedEntrySet.add(this.entryService.remindMeEntry())
      this.emitSelectionChanged()
    }
    else if (event.notNow) {
      this.selectedEntrySet.clear()
      this.selectedEntrySet.add(this.entryService.notNowEntry())
      this.emitSelectionChanged()
    }
  }
}
