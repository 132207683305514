import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, Inject, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  ContentService,
  SharedChatService,
  SharedUserService,
  EntryService,
  EntryState,
  UtilityService,
  OpenAIDriverService,
  AuthService} from '@cheaseed/cheaseed-core';
import { AlertController, ItemReorderEventDetail } from '@ionic/angular';
import { Subject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'cheaseed-chat-player',
  templateUrl: './chat-player.component.html',
  styleUrls: ['./chat-player.component.scss']
})
export class ChatPlayerComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>() // emit on destroy to unsubscribe all subscriptions created with takeUntil

  userService = inject(SharedUserService)

  loading = false;
  @Input() currstmt: any;
  @Input() includeNav = false
  @Input() completionTextBlock: SafeHtml|null = null;
  @Output() forward = new EventEmitter<boolean>()
  @Output() completed = new EventEmitter<boolean>()
  launchedInApp = true
  termModalState: any = { open: false, term: '', key: '', content: null } 
  isAdminRole$ = this.userService.isAdminRole$
  
  constructor(
    @Inject('environment') private environment: any,
    private router: Router, 
    private alertController: AlertController,
    public conversationService: SharedChatService,
    public contentService: ContentService,
    public entryService: EntryService,
    public openai: OpenAIDriverService,
    private utilityService: UtilityService,
    public auth: AuthService,
    private sanitizer: DomSanitizer    ) { }

  ngOnInit() {
    // console.log("ChatPlayerComponent ngOnInit")
    this.launchedInApp = this.conversationService.launchedInApp()
    // if (this.completionTextBlock) console.log("completionTextBlock", this.completionTextBlock)

    // Register listener for clickTerm custom event
    window.addEventListener("clickTerm", (ev: any) => {
      console.log("clickTerm", ev, this)
      const { term, key } = ev.detail
      const content = this.sanitizer.bypassSecurityTrustHtml(this.contentService.getGlobal(key))
      this.termModalState = { open: true, term, key, content }
    })
  }

  ngOnDestroy() {
    // console.log("ChatPlayerComponent ngOnDestroy")
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  isProduction() {
    return !!this.environment.production 
  }
  
  continueForward() {
    this.forward.emit(true)
  }

  async radioGroupChanged(ev:any) {
    // console.log("radioGroupChanged", ev, this.currstmt.attribute)
    if (ev.target.value === 'Other') {
      await this.clickOther(ev)
    }
    else {
      this.currstmt.attribute.changed = true
      this.currstmt.attribute.otherChecked = false
      this.currstmt.attribute.value = ev.target.value
      const selectedOpt = this.currstmt.attribute.optionLinks.find((opt:any) => opt.name === ev.target.value)
      this.presentOptionMessage(selectedOpt)
      // if (!this.conversationService.attributeHasBehavior(this.currstmt.attribute, BEHAVIOR_USER_NEXT_TO_ADVANCE))
      //   timer(400)
      //     .subscribe(() => {
      //       this.continueForward()
      //     })
    }
  }

  scaleChanged(ev:any) {
    this.currstmt.attribute.changed = true
    // console.log("scaleChanged", ev.target.value)
  }

  async presentOptionMessage(opt:any) {
    if (opt?.messageIfSelected)
      await this.utilityService.notify({
        header: opt.description,
        message: opt.messageIfSelected
      })
  }


  async clickOther(ev:any) {
    // console.log("clickOther", this.currstmt.attribute)
    if (!this.currstmt.attribute.otherChecked && !this.loading) {
      const alert = await this.alertController.create({
        header: 'Add New',
        inputs: [
          {
            name: 'otherValue',
            type: 'text',
            placeholder: 'Add New',
            value: this.currstmt.attribute.otherValue
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          }, 
          {
            text: 'Ok',
            handler: data => {
              this.currstmt.attribute.value = data.otherValue
              this.currstmt.attribute.otherChecked = data.otherValue ? true : false
              this.currstmt.attribute.changed = true
              // if (this.currstmt.attribute.inputType !== 'MULTIOPTIONS' && !this.conversationService.attributeHasBehavior(this.currstmt.attribute, BEHAVIOR_USER_NEXT_TO_ADVANCE))
              //   this.continueForward()
            }
          }
        ]
      });
      await alert.present();
    }
  }

  pinChat() {
    this.conversationService.pinConversation()
  }

  unpinChat() {
    this.conversationService.unpinConversation()
  }

  async shareTile() {
    return await this.conversationService.clickTakeawayTile(this.currstmt.name, null, true)
  }

  onTakeawayImageLoad() {
    // console.log("image loaded")
    this.currstmt.imageLoaded = true
  }

  async tapContent() {
    const disableNav = await firstValueFrom(this.conversationService.disableNavigation$)
    if (!disableNav && this.conversationService.shouldForward())
      this.continueForward()
  }

  addEntrySpec(specName: string) {
    if (this.launchedInApp)
      this.router.navigate([ 'sectionentryform', {
        entrySpecId: specName, 
        suppressQuickAdd: true, 
        currentAddableEntryMapId: this.currstmt.name 
      } ])
    else
      this.utilityService.notify({ message: this.contentService.getGlobal("unableToLaunchOnWeb.message") })
  }

  isEntrySaved(state: EntryState) {
    return state === EntryState.Saved
  }

  feedbackChanged(feedback:any) {
    console.log("feedbackChanged", feedback)
    this.conversationService.setChatFeedback(feedback)
  }

  sliderFormat(params: any, value: number): string {
    // console.log("sliderFormat", value, params)
    if (value !== undefined) {
      const result = value > 1000 ?
        (params.useK ? Math.round(value / 1000) + 'K' : 
          params.useCommas ? value.toLocaleString() : 
          `${value}`) :
        `${value}`
      return params.useDollar ? '$' + result : result
    }
    else {
      return ''
    }
  }

  async signUp() {
    try {
      await this.auth.convertFromAnonymous()
      this.completed.emit(true)
    }
    catch (err:any) {
      if (err.code === 'auth/credential-already-in-use')
        await this.utilityService.notify({
          header: 'The account selected is already in use. Please sign in with that account.'
        })
    }
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>, attribute: any) {

    type OptionType = { name: string, description: string }
    let items = attribute.optionLinks
    // Before complete is called with the items they will remain in the order before the drag
    // console.log('Before complete', items)

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    items = ev.detail.complete(items)

    // After complete is called the items will be in the new order
    // console.log('After complete', items)

    const val = items.map((item:OptionType) => item.name)
    attribute.value = val
    attribute.changed = true
    // console.log("attribute ranking", attribute)
  }

}
