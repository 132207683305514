<ion-app>
  <ion-split-pane
    when="lg"
    contentId="main-content"
    style="--side-max-width: 340px; height: 100%;">
    <ion-menu [swipeGesture]="false" autoHide="false" type="overlay" contentId="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button auto-hide="false" data-testid="hamburgerMenu"></ion-menu-button> 
            <img src="assets/shared/img/menulogo.svg" style="padding: 0 0 6px 10px">
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>      
        <div *ngFor="let p of authorizedPages$ | async">
          <!-- Standard Menu Item -->
          <ion-menu-toggle autoHide="false">
            <ion-item *ngIf="p.url" [routerLink]="p.url" routerDirection="root" routerLinkActive="active" [attr.data-testid]="p.title.replaceAll(' ', '')" >
              <ion-label>
                {{ p.title || getPlural(p.name) }}
              </ion-label>
            </ion-item>  
            <ion-item 
              *ngIf="p.handler" 
              (click)="p.handler()" 
              [class]="p.isAdmin ? 'menu-item-admin' : ''"
              [attr.data-testid]="p.title.replaceAll(' ', '')" >
              <ion-label>
                {{ p.title || getPlural(p.name) }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!-- Item with Children -->
          <ion-item button *ngIf="p.sections?.length > 0" (click)="p.open = !p.open" [class.parent-active]="p.open" detail="false">
            <ion-icon slot="start" name="chevron-forward-sharp" *ngIf="!p.open"></ion-icon>
            <ion-icon slot="start" name="chevron-down-sharp" *ngIf="p.open"></ion-icon>
            <ion-label>{{ p.title || getPlural(p.name) }}</ion-label>
          </ion-item>         
          <!-- Children List for clicked Item -->
          <ion-list *ngIf="p.open">
            <ng-container *ngFor="let sub of p.sections">
              <ion-item *ngIf="sub.sections?.length > 0" (click)="sub.open = !sub.open" [class.parent-active]="sub.open" detail="false" class="sub-item">
                <ion-icon slot="start" name="chevron-forward-sharp" *ngIf="!sub.open"></ion-icon>
                <ion-icon slot="start" name="chevron-down-sharp" *ngIf="sub.open"></ion-icon>
                <ion-label>{{ sub.title || getPlural(sub.name) }}</ion-label>
              </ion-item>
              <ion-menu-toggle *ngIf="!sub.sections" autoHide="false">
                <ion-item (click)="clickSection2(sub)" class="sub-item">
                  <!-- <ion-icon *ngIf="sub.icon" [name]="sub.icon" slot="start"></ion-icon> -->
                  <ion-label>{{ sub.title || getPlural(sub.name) }}</ion-label>
                </ion-item>
              </ion-menu-toggle>      
              <!-- Children List for subitem -->
              <ion-list *ngIf="sub.open">
                <ion-menu-toggle autoHide="false">
                  <ion-item *ngFor="let subsub of sub.sections" class="sub-sub-item" (click)="clickSection2(subsub)">
                  <!-- <ion-icon *ngIf="subsub.icon" [name]="subsub.icon" slot="start"></ion-icon> -->
                  <ion-label>{{ subsub.title || getPlural(subsub.name) }}</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              </ion-list>
            </ng-container>
          </ion-list>
        </div>
      </ion-content>
      <ion-footer style="text-align: left; padding: 16px; font-size: 12px;">
        <ng-container *ngIf="appInfo$ | async as info">
          <div><b>{{ info.user.docId }}</b></div>
          <div>Release: {{ info.environment?.releaseTag }}</div>
          <div *ngIf="info.environment?.patchLevel > 0">PatchLevel: {{ info.environment?.patchLevel }}</div>
          <div>Build: {{ info.environment?.buildTag }}</div>
          <div>Content: {{ info.contentTimestamp }}</div>
        </ng-container> 
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content" main></ion-router-outlet>
  </ion-split-pane>
</ion-app>
