<div class="header">
    <cheaseed-global key="chat.feedback.header" default="This Chat makes me feel">
    </cheaseed-global>
</div>            
<div class="icon-bar">
    <div>
        <button [class]="states['confident'] ? 'selected' : 'unselected'" mat-fab (click)="toggleConfident(); $event.stopPropagation()">
            <mat-icon class="material-icons-outlined">brightness_low</mat-icon>
        </button>
        <div class="icon-label" [style]="states['confident'] ? 'color: var(--chea-hotpink);' : 'color: black;'">Confident</div>
    </div>
    <div>
        <button [class]="states['motivated'] ? 'selected' : 'unselected'" mat-fab (click)="toggleMotivated(); $event.stopPropagation()">
            <mat-icon class="material-icons-outlined">workspace_premium</mat-icon>
        </button>
        <div class="icon-label" [style]="states['motivated'] ? 'color: var(--chea-hotpink);' : 'color: black;'">Motivated</div>
    </div>
    <div>
        <button [class]="states['smarter'] ? 'selected' : 'unselected'" mat-fab (click)="toggleSmarter(); $event.stopPropagation()">
            <mat-icon class="material-icons-outlined">lightbulb</mat-icon>
        </button>
        <div class="icon-label" [style]="states['smarter'] ? 'color: var(--chea-hotpink);' : 'color: black;'">Smarter</div>
    </div>
</div>                        
