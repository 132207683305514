<ng-container *ngIf="challengeService.userChallenges$ | async as challenges">
  <ng-container *ngIf="selectedType === ChallengeState.Current">
    <ng-container *ngIf="contentService.loader$ | async">
      <cheaseed-instruction-card *ngIf="challenges.activeChallenges.length === 0" key="emptychallenges.message"></cheaseed-instruction-card>
    </ng-container>
    <cheaseed-challenge-list [challenges]="challenges.activeChallenges"></cheaseed-challenge-list>
    <div style="padding: 16px 0 0 0; text-align: center;">
      <ion-button color="secondary" style="font-weight: bold" (click)="clickAddChallenge('private', challengeService.privateChallengeSpecs)">
        Create Challenge
      </ion-button>
      <ng-container *ngIf="challengeService.availablePublicChallengeSpecs$ | async as publicChallenges">
        <ion-button 
            *ngIf="publicChallenges.length > 0"
            color="secondary" 
            style="font-weight: bold; padding-left: 8px;" 
            (click)="clickAddChallenge('public', publicChallenges)">
            Join Challenge
        </ion-button>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedType === ChallengeState.Completed">
    <cheaseed-challenge-list [challenges]="challenges.completedChallenges" [completed]="true"></cheaseed-challenge-list>
  </ng-container>
</ng-container>