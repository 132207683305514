import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule, HammerModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { SETTINGS } from '@angular/fire/compat/firestore'
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app'
import { connectFunctionsEmulator, FunctionsModule, getFunctions, provideFunctions } from '@angular/fire/functions'
import { initializeFirestore, connectFirestoreEmulator, provideFirestore } from '@angular/fire/firestore'
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage'
import { provideAuth, getAuth, initializeAuth, indexedDBLocalPersistence, Auth } from '@angular/fire/auth'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'

import { environment } from '../environments/environment'
import { ComponentsModule } from './components/components.module'
import { ServiceWorkerModule } from '@angular/service-worker'
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx'
import '@teamhive/capacitor-video-recorder'
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx'
import { Capacitor } from '@capacitor/core'
import { PatchLevel, ReleaseTag } from 'releasetag'
import { BuildTag } from 'buildtag'
import { Purchases } from '@awesome-cordova-plugins/purchases/ngx'
import { DateFnsModule } from 'ngx-date-fns';
import { AirtableBase, ExtractService, STRIPE_TEST_PUBLISHABLE_KEY } from '@cheaseed/node-utils';
import { HandlebarsBase } from '@cheaseed/node-utils';
import { NgxStripeModule } from 'ngx-stripe'
import { GlobalErrorHandler, UtilityService } from '@cheaseed/cheaseed-core'
import { CurrencyPipe } from '@angular/common'

@NgModule({ 
    declarations: [AppComponent],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        IonicModule.forRoot({ swipeBackEnabled: false, innerHTMLTemplatesEnabled: true }),
        AppRoutingModule,
        FunctionsModule,
        DateFnsModule,
        NgxStripeModule.forRoot(STRIPE_TEST_PUBLISHABLE_KEY),
        BrowserAnimationsModule,
        ComponentsModule,
        HammerModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })], providers: [
        SocialSharing,
        CleverTap,
        Purchases,
        provideHttpClient(withInterceptorsFromDi()),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            let auth: Auth;
            if (Capacitor.isNativePlatform()) {
                auth = initializeAuth(getApp(), {
                    persistence: indexedDBLocalPersistence
                });
            }
            else {
                auth = getAuth();
            }
            /*if (environment.useEmulators) {
              connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
            }*/
            return auth;
        }),
        provideFirestore(() => {
            const firestore = initializeFirestore(getApp(), { ignoreUndefinedProperties: true });
            if (environment.useEmulators) {
                connectFirestoreEmulator(firestore, 'localhost', 9198);
            }
            return firestore;
        }),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            return storage;
        }),
        provideFunctions(() => {
            const functions = getFunctions();
            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, 'localhost', 9200);
            }
            return functions;
        }),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: SETTINGS, useValue: { ignoreUndefinedProperties: true } },
        { provide: 'environment', useValue: { ...environment, buildTag: BuildTag, releaseTag: ReleaseTag, patchLevel: PatchLevel } },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: 'AirtableService', useClass: AirtableBase },
        { provide: 'HandlebarsBase', useClass: HandlebarsBase },
        { provide: CurrencyPipe, useClass: CurrencyPipe },
        { provide: 'ExtractService', useClass: ExtractService },
        { provide: 'UtilityService', useClass: UtilityService },
    ] })
export class AppModule {}
