import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'cheaseed-recommended-chat-feed',
  templateUrl: './recommended-chat-feed.component.html',
  styleUrls: ['./recommended-chat-feed.component.scss']
})
export class RecommendedChatFeedComponent implements OnInit {
  recommendedChats$: Observable<any> = of(null)

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  click(chat: any) {
    this.router.navigate( ['conversation', chat.name, { backLabel: 'Home', launchSource: "Home" } ] )
  }
}