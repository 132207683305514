<div class="challenges-div" *ngIf="challenges.length > 0">
  <!-- <ion-list-header>{{ listTitle }}</ion-list-header> -->
  <ion-list class="item-list">
    <ion-item
      *ngFor="let item of challenges; trackBy: utilityService.trackByDocId"
      (click)="clickChallenge(item)"
      lines="full"
      class="challenge-item"
      detail="true">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="12" class="ion-no-padding">
            <h2>{{ item.title }}</h2>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="1" class="ion-no-padding" style="text-align: left; align-self: center;">
            <mat-icon
              data-testid="inviteIcon"
              class="material-icons-outlined"                      
              (click)="challengeService.invite(item); $event.stopPropagation();">ios_share</mat-icon>
          </ion-col>
          <ion-col size="11" class="ion-no-padding" style="padding-left:10px;">
            <div class="challenge-detail" *ngIf="!item.public">
              Created by {{ item.owner ? "me" : item.ownerNickname }}
            </div>
            <div class="challenge-detail" *ngIf="item.public">
              Joined {{ item.createdAt | isoDateStr | dfnsFormatDistanceToNow: { addSuffix: true } }}
            </div>
            <div *ngIf="!completed" class="challenge-detail">
                {{ item.startDate < todaysDate ? "Started" : "Starting" }} {{ item.startDate | isoDateStr | dfnsFormatDistanceToNow: { addSuffix: true } }}
            </div>
            <div *ngIf="completed" class="challenge-detail">
                Completed {{ item.projectedEndAt | isoDateStr | dfnsFormatDistanceToNow: { addSuffix: true } }}
            </div>
            </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>
</div>
<ng-container *ngIf="challenges.length === 0 && completed">
  <div style="padding: 20px; font-weight: bold;">No {{ completed ? 'completed' : 'active' }} challenges.</div>  
</ng-container>
