import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService, EntryService } from '@cheaseed/cheaseed-core';
import { Entry } from '@cheaseed/node-utils';
import { ActionSheetController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cheaseed-collection-selector',
  templateUrl: './collection-selector.component.html',
  styleUrls: ['./collection-selector.component.scss'],
})
export class CollectionSelectorComponent implements OnInit {
  // Communicate via selected 
  @Input() selected: any[] = []
  @Output() collectionChanged = new EventEmitter()

  isModalOpen$ = new BehaviorSubject(false)
  modalData$ = new BehaviorSubject<any>(null)
  selectedEntrySet: Set<Entry> = new Set()
  selectedEntryTypes: any[] = []
  collectibleTypes: Map<string, any> = new Map()

  constructor(
    private entryService: EntryService,
    private contentService: ContentService,
    private actionSheetController: ActionSheetController
  ) { }

  ngOnInit(): void {
    // Get entry objects for selected ids
    console.log("selected", this.selected)
    this.selectedEntrySet = new Set(this.entryService.getEntriesByIds(this.selected))
    // Compute available types
    const types = this.contentService.getCollectibleTypes()
    const typesToSort = new Map<string, any>(types.map((t:any) => [ this.contentService.getPluralGlobal(t.id), t ]))
    const sortOrder = Array.from(typesToSort.keys()).sort((a, b) => a < b ? -1 : 1)
    const typeMap = new Map<string, any>(sortOrder.map((key:any) => [key, typesToSort.get(key)]))
    console.log("collectibleTypes", typeMap)
    this.collectibleTypes = typeMap
    this.updateViewModel()
  }

  addEntry(entry: any) {
    this.selectedEntrySet.add(entry as Entry)
    this.updateViewModel()
    this.emitCollectionChanged()
  }

  removeEntry(entry: any) {
    this.selectedEntrySet.delete(entry as Entry)
    this.updateViewModel()
    this.emitCollectionChanged()
  }

  emitCollectionChanged() {
    this.collectionChanged.emit( { selected: Array.from(this.selectedEntrySet).map(e => e.docId) } )
  }

  updateViewModel() {
    const types:any = {}
    for (const entry of this.selectedEntrySet) {
      const key = this.contentService.getPluralGlobal(entry.indexType() as string)
      if (!types[key]) 
        types[key] = []
      types[key].push(entry)
    }
    const keys = Object.keys(types)
    keys.sort((a, b) => a < b ? -1 : 1)
    this.selectedEntryTypes = keys.map(key => ({ key, entries: types[key] }))
  }

  getCancelButton(handler:any = null) {
    return { 
      text: 'Not now',
      icon: 'close',
      cssClass: 'cancel-button',
      handler: handler || (() => {})
    }
  }

  async clickAdd() {
    // Implement action sheet cascade for Collectible entry types
    let typesWithEntries = Array.from(this.collectibleTypes.entries())
    // console.log("typesWithEntries", typesWithEntries)
    // Compute available entries from all
    const availableEntries = this.entryService.getEntriesOfType().filter(entry => !this.selectedEntrySet.has(entry))
    // console.log("availableEntries", availableEntries)
    // Compute available types
    typesWithEntries = typesWithEntries.filter(t => availableEntries.find(e => t[1].id === e.indexType()))
    // console.log("typesWithEntries", typesWithEntries)

    const buttons:any = typesWithEntries.map(e =>({
      text: e[0],
      handler: async () => await this.nextAddSheet(e)
    }))
    buttons.push(this.getCancelButton())
    // console.log(buttons)
    const ac = await this.actionSheetController.create({
      header: "Add Entry",
      cssClass: "plus-action-sheet",
      translucent: true,
      backdropDismiss:true,
      buttons: buttons
    })
    return await ac.present()
  }

  async nextAddSheet(menu: any) {
    const entrySpec = menu[1]
    const typedEntries = this.entryService.getEntriesOfType(entrySpec.id)
    const availableToAdd = typedEntries.filter(e => !this.selectedEntrySet.has(e))
    // console.log(entrySpec, availableToAdd)
    this.modalData$.next({
      entryTitle: menu[0],
      availableEntries: availableToAdd
    })
    this.isModalOpen$.next(true)
  }

  dismissModal() {
    this.isModalOpen$.next(false)
  }

  modalSelectionChanged(event: any) {
    this.dismissModal()
    const entry = event.selected[0]
    if (entry) {
      this.addEntry(entry)
    }
  }  

}
