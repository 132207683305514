import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from '@cheaseed/cheaseed-core';
import { Observable, of } from 'rxjs';

export interface FilterChip {
  type: string;
  title?: string;
  admin?: boolean;
}

@Component({
  selector: 'cheaseed-scrolling-filter-chips',
  templateUrl: './scrolling-filter-chips.component.html',
  styleUrls: ['./scrolling-filter-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollingFilterChipsComponent implements OnInit {

  @Input() chips:Observable<FilterChip[]> = of([])
  @Input() selected = ""
  @Input() minWidth = ""
  @Output() filterChipSelected = new EventEmitter<any>()
  
  constructor(
    public utilityService:UtilityService
  ) { }

  ngOnInit() { }

  setFilterChip(item:any) {
    if (this.selected !== item.type) {
      this.selected = item.type
      this.filterChipSelected.emit( { selected: item.type } )
    }
  }

  trackElement(index: number, element: any) {
    return element.type
  }

}
