import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CHALLENGE_FEED, ContentService, SharedChallengeService, SharedEventService, SharedUserService } from '@cheaseed/cheaseed-core';
import { ChallengeSpec, diff, todaystr } from '@cheaseed/node-utils';
import { filter, map, Observable, of } from 'rxjs';

@Component({
  selector: 'cheaseed-challenge-feed',
  templateUrl: './challenge-feed.component.html',
  styleUrls: ['./challenge-feed.component.scss']
})
export class ChallengeFeedComponent implements OnInit {
  feedState$: Observable<any> = of(null)
  @Input() challenge: any = null

  constructor(
    private router: Router,
    private contentService: ContentService,
    private challengeService: SharedChallengeService,
    private eventService: SharedEventService,
    private userService: SharedUserService,
  ) { }

  // The ChallengesPublicInvitedFeed widget is enabled on Home two weeks prior to a public challenge starting
  // The widget routes the user to the join challenge page for the public challenge
  // The widget expires when the user taps the widget, joins the public challenge (from this widget or elsewhere in the app), or the widget has been present on the page >14 days

  ngOnInit(): void {
    this.feedState$ = this.challengeService.challengeFeed$
      .pipe(
        filter(specs => specs && specs.length > 0),
        map(specs => {
          // console.log("ChallengeFeedComponent", specs)
          let state:any = null
          if (specs.length > 0) {
            state = this.prepareState(CHALLENGE_FEED, specs[0])
          }
          return state
        }
      ))
  }

  prepareState(type: string, spec: ChallengeSpec) {
    let state:any = null
    const feedKey = this.challengeService.getFeedKey(CHALLENGE_FEED, spec)
    const lastValue = this.userService.getUserKey(feedKey)
    const lastFeedDate = lastValue?.lastFeedDate
    const lastTapped = lastValue?.tapped
    if (!lastTapped) {
      const daysSinceFeedDate = diff(lastFeedDate, null, 'days')
      if (!lastFeedDate || (daysSinceFeedDate <= 14)) {
        state = {
          type: type,
          spec: spec,
          title: this.getTitle(type)?.replace('$challengeName', spec.title),
          subtitle: this.getSubtitle(type),
          route: ['challenge-public-join', spec.name ]          
        }
        if (!lastFeedDate || (daysSinceFeedDate >= 14)) {
          this.userService.setUserKey(feedKey, 
            { lastFeedDate: todaystr(), tapped: false })
        }
      }
    }
    return state
  }

  getTitle(type: string) {
    const str = type[0].toLowerCase() + type.slice(1)
    return this.contentService.getGlobal(`${str}.title`)
  }

  getSubtitle(type: string) {
    const str = type[0].toLowerCase() + type.slice(1)
    return this.contentService.getGlobal(`${str}.subtitle`)
  }

  clickFeedItem(state:any) {
    this.challengeService.updateChallengeFeed(state.spec)
    if (state.route)
      this.router.navigate(state.route)
    this.eventService.recordClick({ button: 'ChallengeFeed', location: 'Home', state: state.type, challengeSpec: state.spec.name })
  }
}
