<div style="display:flex; justify-content:">
    <ion-button color="medium" expand="full" (click)="clickSelect()" style="height: 44px;">
        <!-- Select {{ buttonTitle || entryTitle }} -->
        Select
    </ion-button>

    <div class="entries">
        <ng-container *ngFor="let entry of selectedEntrySet.values()">
            <ion-item
                lines="none"
                class="selected-entry"
                detail="false">
                <ion-label class="ion-text-wrap">
                    {{ entry.displayName }}
                </ion-label>
                <ion-button slot="end" fill="clear" (click)="removeEntry(entry)">
                    <ion-icon name="remove-circle-outline" slot="end"></ion-icon>
                </ion-button>
            </ion-item>
        </ng-container>
    </div>
</div>

<ion-modal 
  [isOpen]="selectClicked$ | async" 
  [canDismiss]="true"
  (didDismiss)="dismissModal()">
  <ng-template>
    <ion-content>
        <cheaseed-entry-selector-modal
            [notNowOption]="maxEntries === 1"
            [remindMeOption]="maxEntries === 1"
            [title]="'Select ' + entryTitle"
            [entries]="availableEntries"
            (selectionChanged)="modalSelectionChanged($event)">
        </cheaseed-entry-selector-modal>
    </ion-content>
  </ng-template>
</ion-modal>