import { Component, Input, OnInit } from '@angular/core';
import { SharedEventService, SharedMessageService } from '@cheaseed/cheaseed-core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'cheaseed-newsflash',
  templateUrl: './newsflash.component.html',
  styleUrls: ['./newsflash.component.scss']
})
export class NewsflashComponent implements OnInit {

  @Input() message:any

  constructor(
    public messageService: SharedMessageService,
    private eventService: SharedEventService,
    private navController: NavController
  ) { }

  ngOnInit(): void {
  }

  clickMessage(msg: any) {
    this.eventService.recordNewsflashView(msg)
    if (msg.detail)
      this.navController.navigateForward([ 'conversation', msg.detail, { backLabel: 'Home', launchSource: "Newsflash" } ])
    this.messageService.viewMessage(msg)
  }

}
