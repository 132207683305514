<ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title style="font-size: 18px; padding: 0 50px 0 50px;">Resources</ion-title>
    </ion-toolbar> 
  </ion-header>
  
  <ion-content>
    <ion-list lines="full">        
      <ng-container *ngFor="let area of statement.areas">
        <ion-list-header *ngIf="area.items.length > 0">
          <ion-label>{{ area.name }}</ion-label>
        </ion-list-header>
        <div *ngFor="let item of area.items" class="drawer">
          <ion-item>
              <a *ngIf="item.url" class="drawertext" (click)="openExternal(item)" [innerHTML]="item.formattedTitle"></a>
              <div *ngIf="!item.url" class="drawertext" style="text-decoration: none" [innerHTML]="item.formattedTitle"></div>
          </ion-item>
        </div>
      </ng-container>
    </ion-list>
</ion-content>