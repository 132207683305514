<ion-item-sliding #slidingItem>
  <ion-item class="item-line"
    lines="none"
    detail="false">
    <ion-card class="chat-library-card">
      <!-- <div *ngIf="playlistMap.has(item.id)" class="corner-ribbon bottom-right green">Playlist</div>
      <div *ngIf="chatsInProgressMap.has(item.id)" class="corner-ribbon bottom-right green">In Progress</div> -->
      <ion-card-content>
        <ion-grid (click)="clickItem(item)">
          <ion-row>
            <ion-col size="12" class="title-col">
              <div style="display: flex; flex-direction: row; justify-content: start; gap: 8px;">
                <mat-icon 
                  class="material-icons" 
                  style="font-size: 32px; width: 32px; height: 32px;"
                  [style.color]="statuses.get(item.name) ? 'green' : 'lightgray'">
                    {{ statuses.get(item.name) || started.has(item.name) ? 'check_circle' : 'radio_button_unchecked' }}
                  </mat-icon>
                <div style="width: 75%">
                  <ion-card-title>{{ item.title || item.name }}</ion-card-title>
                  <ion-card-subtitle>{{ item.subtitle }}</ion-card-subtitle>
                </div>
                <div *ngIf="item.estimatedTime" style="width: 15%; font-size: 12px; text-align: right;">
                  {{ item.estimatedTime }} min
                </div>
              </div>
            </ion-col>
          </ion-row>
          <!-- <ion-row>
            <ion-col size="12">
              <ion-icon name="{{ statuses.get(item.id) ? 'checkbox-outline' : 'square-outline' }}" class="chat-checkbox"></ion-icon>
              <span class="status" *ngIf="playlist.has(item.id)">In {{ getTerm("Playlist") }} </span>
              <span class="status" *ngIf="started.has(item.id)"> Started</span>
            </ion-col>
          </ion-row> -->
          <ion-row class="icons-row">
            <ion-col size="11" class="icons-col">
              <div class="left-icons">
                <mat-icon
                  *ngIf="isShareable"
                  data-testid="shareButton"
                  class="material-icons-outlined"
                  (click)="shareChat(item); $event.stopPropagation();">ios_share</mat-icon>
                <mat-icon
                  data-testid="pinButton"
                  class="material-icons-outlined"
                  [style.color]="pinned.has(item.name) ? 'var(--chea-purple)' : 'gray'"
                  (click)="togglePin(item); $event.stopPropagation();">push_pin</mat-icon>
              </div>
            </ion-col>
            <ion-col class="rightmost-col" [matMenuTriggerFor]="beforeMenu" (click)="$event.stopPropagation()">
              <ion-icon id="ellipsisMenu" name="ellipsis-horizontal" size="small"></ion-icon>
              <mat-menu #beforeMenu="matMenu" yPosition="below">
                <ng-template matMenuContent>
                  <button mat-menu-item (click)="clickItem(item)">{{ started.has(item.name) ? 'Resume' : 'Start' }} Chat</button>
                  <button mat-menu-item *ngIf="canPin(item)" (click)="pinChat(item)">Pin Chat</button>
                  <button mat-menu-item *ngIf="canUnpin(item)" (click)="removeFromPinned(item)">Remove from Pinned</button>
                  <button mat-menu-item *ngIf="isShareable" (click)="shareChat(item)">Share</button>    
                </ng-template>
              </mat-menu>  
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-item>
  <ion-item-options side="end">
    <ion-item-option *ngIf="canPin(item)" (click)="pinChat(item); slidingItem.close()">
      Pin Chat
    </ion-item-option>
    <ion-item-option *ngIf="canUnpin(item)" (click)="removeFromPinned(item); slidingItem.close()">
      Remove from Pinned
    </ion-item-option>
    <!-- <ion-item-option *ngIf="playlist.has(item.id)" (click)="removeFromPlaylist(item.id); slidingItem.close()">
      Remove from {{ getTerm("Playlist") }}
    </ion-item-option>
    <ion-item-option *ngIf="!playlist.has(item.id) && !started.has(item.id)" (click)="addToPlaylist(item.id); slidingItem.close()">
      Add to {{ getTerm("Playlist") }}
    </ion-item-option>
    <ion-item-option *ngIf="started.has(item.id)" (click)="removeInProgress(item.id); slidingItem.close()">
      Remove from Started
    </ion-item-option> -->
  </ion-item-options>
</ion-item-sliding>