import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HomeItem, ItemActionType, ItemDispositionType, ContentService, ItemState } from '@cheaseed/cheaseed-core';

@Component({
  selector: 'cheaseed-home-item',
  template: `
  <div class="home-item" style="padding: 10px;">
  <ion-item-sliding #slidingItem>
    <ion-item 
      lines="none" 
      (click)="this.clicked.emit(item)">
      <div class="item-flex">  
        <div class="details-wrapper">
            <div *ngIf="title" class="title">
              <div>{{ title }}
                <span *ngIf="item.required" class="star">☆</span>
              </div>
            </div>  
            <div *ngIf="message" class="subtitle">
              <div [innerHtml]="message"></div>
              <span *ngIf="item.required && !title" class="star"> ☆</span>
            </div>
        </div>
        <div *ngIf="estimatedTime && item.state !== ItemState.DONE" class="time-div">
          {{ estimatedTime }} min
        </div>
        <div *ngIf="item.state !== ItemState.DONE">
          <mat-icon          
            class="icon material-icons-outlined" 
            [style.color]="item.completed ? 'var(--chea-purple)' : 'darkgray'">
            {{ item.completed ? 'check_circle' : item.icon || icons[item.actionType] }}
          </mat-icon>
        </div>
        <div *ngIf="item.state === ItemState.DONE" class="time-div">
        {{ item.updatedAt | dfnsFormatDistanceToNow: { addSuffix: true } }}
        </div>
      </div>
    </ion-item>
    <ion-item-options side="end">
      <ion-item-option 
        *ngIf="!item.level && !item.completed && item.state !== ItemState.SKIPPED && !hideSlidingItems"
        (click)="this.dismissed.emit(item); slidingItem.close()">
          {{ item.onDismissed === ItemDispositionType.REMOVED ? "Remove" : "Skip" }}
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</div>
`,
  styleUrls: [ './home-item.component.scss' ]
})
export class HomeItemComponent implements OnInit {
  @Input() item = {} as HomeItem
  @Input() hideSlidingItems = false
  @Output() clicked = new EventEmitter<HomeItem>()
  @Output() dismissed = new EventEmitter<HomeItem>()

  readonly ItemActionType = ItemActionType
  readonly ItemDispositionType = ItemDispositionType
  readonly ItemState = ItemState
  estimatedTime: string | null = null
  
  message:SafeHtml|null = null
  title = ''

  icons:Record<ItemActionType, string> = {
    [ItemActionType.CHAT]: 'grading_outline',
    [ItemActionType.ENTRY]: 'edit_outline',
    [ItemActionType.ROUTE]: 'arrow_forward_ios',
    [ItemActionType.SHARE]: 'ios_share_outline'
  }

  constructor(
    private contentService: ContentService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    let msg = 
      this.item.message || 
      (this.item.actionType === ItemActionType.CHAT ? this.messageForChatNamed(this.item) : this.messageForEntrySpecNamed(this.item)) 
    this.title = 
      this.item.title || 
        (this.item.actionType === ItemActionType.CHAT ? this.titleForChatNamed(this.item) : this.titleForEntrySpecNamed(this.item))
    msg = msg === '!' ? null : msg
    this.message = msg ? this.sanitizer.bypassSecurityTrustHtml(msg) : null
    this.title = this.title === '!' ? '' : this.title
    this.estimatedTime = 
      this.item.actionType === ItemActionType.CHAT 
      ? this.contentService.getConversationNamed(this.item.actionId)?.estimatedTime 
      : null
  }

  titleForChatNamed(item: HomeItem) {
    return this.contentService.getConversationNamed(item.actionId)?.title
  }

  titleForEntrySpecNamed(item: HomeItem) {
    return null
  }

  messageForChatNamed(item: HomeItem) {
    return this.contentService.getConversationNamed(item.actionId)?.subtitle
  }

  messageForEntrySpecNamed(item: HomeItem) {
    return null
  }

}
