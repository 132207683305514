import { AppSource } from '@cheaseed/node-utils';

export const environment = {
  appSource: AppSource.App,
  production: false,
  devPwd: 'CheaTest2023!',
  useEmulators: false,
  linkHost: 'https://share-dev.cheaseed.com',
  firebase: {
    apiKey: 'AIzaSyCVSz4hXNSVo48Kn30aofjHunDn3eKeji4',
    authDomain: 'cheaseed-dev-bb89c.firebaseapp.com',
    databaseURL: 'https://cheaseed-dev-bb89c.firebaseio.com',
    projectId: 'cheaseed-dev-bb89c',
    storageBucket: 'cheaseed-dev-bb89c.appspot.com',
    messagingSenderId: '232970175996',
    appId: '1:232970175996:web:c1e032401f24a9812d9e79',
    measurementId: 'G-XNV5T125K0',
  },
  clevertap: {
    accountID: 'TEST-K5K-R79-7R6Z',
    passCode: 'CTM-IMV-IWUL',
  },
  branch: {
    url: 'cheaseed.test-app.link',
  },
  azure: {
    endpoint: 'https://cheaseed-australia.openai.azure.com/',
    key: '58587c75fdd54a6aa0de9b46e51d140b',
    models: ['gpt-4-1106-preview', 'gpt-35-turbo-16k', 'gpt-4'],
  },
  globalErrors: {
    slackURL:
      'https://hooks.slack.com/services/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',
    channelName: '#dev-runtime-errors',
  },
  appleClientId: 'com.cheaseed.app1.signin',
  googleWebClientId:
    '232970175996-ku6oibdcgk7dg6qlmt6dv4igm13v32t2.apps.googleusercontent.com',
  // Remember to update ios/App/App/Info.plist with REVERSED_CLIENT_ID from GoogleService-Info.plist
  androidRevenueCatKey: 'goog_hAEVRERCfBSqqPEKzCXPCWoIwDw',
  iosRevenueCatKey: 'appl_LPjoJqyaJGsdVPkQslgRUTnqjih',
};
