import { Component, Input, OnInit } from '@angular/core';
import { StatsFeedService, StatsFeedState } from '@cheaseed/cheaseed-core';
@Component({
  selector: 'cheaseed-stats-feed',
  templateUrl: './stats-feed.component.html',
  styleUrls: ['./stats-feed.component.scss']
})
export class StatsFeedComponent implements OnInit {
  
  @Input() state: StatsFeedState | null = null
  isPreviewOpen = false
  previewUrl: string | null = null

  constructor(
    private statsFeedService: StatsFeedService
  ) { }

  ngOnInit() {
    // console.log("StatsFeedComponent.ngOnInit()", this.state)
    if (this.state?.showHelp) {
      const key = `preview.${this.state.type}.url`
      this.previewUrl = this.statsFeedService.getContent(key) || 'https://cheaseed.github.io/cheaseed/public/interstitialFirstFilterChangev2.gif'
    }
  }

  clickFeedItem(state:StatsFeedState|null) {
    this.statsFeedService.tappedFeed(state)
  }

  clickHelp(state:StatsFeedState|null) {
    this.isPreviewOpen = true
    // console.log("StatsFeedComponent.clickHelp()", state)
  }
}
