import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'cheaseed-series-intro',
  templateUrl: './series-intro.component.html',
  styleUrls: ['./series-intro.component.scss'],
})
export class SeriesIntroComponent implements OnInit {

  @Input() series: any;
  @Input() chat: any;
  // private timer: any;
  
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() { 
    // // Always dismiss after timeout
    // this.timer = timer(30000)
    //   .subscribe(() => {
    //     this.dismiss()
    //   })
  }

  async dismiss() {
    console.log("Dismissing SeriesIntroComponent")
    this.modalController.dismiss()
    // this.timer.unsubscribe()
  }

}
