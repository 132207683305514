<div class="outer-div">
    <div class="title-bar" (click)="dismiss()">
      <div class="title">{{ title || 'Select ' + numberToSelect }}</div>
      <ion-icon name="close"></ion-icon>
    </div>
    <div class="entries-content">
      <cdk-virtual-scroll-viewport appendOnly itemSize="10" class="viewport">
        <ion-item button
          *cdkVirtualFor="let entry of entries"
          (click)="selectEntry(entry)"
          lines="none"
          detail="false">
          {{ entry.displayName }}
        </ion-item>
        <ion-item button *ngIf="notNowOption" color="medium" lines="none" detail="false" (click)="clickNotNow()">
          <i>Not Now</i>
        </ion-item>
        <ion-item button *ngIf="remindMeOption" color="medium" lines="none" detail="false" (click)="clickRemindMe()">
          <i>Remind Me</i>
        </ion-item>
      </cdk-virtual-scroll-viewport>
    </div>
</div>