import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core'
import { Router } from '@angular/router'
import { Conversation } from '@cheaseed/node-utils'

@Component({
  selector: 'cheaseed-resources-browser',
  templateUrl: './resources-browser.component.html',
  styleUrls: ['./resources-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesBrowserComponent implements OnInit {

  @Input() categories: any[] = []
  @Input() opened: any[] = []
  @Input() backLabel?: string
  @Output() categoryClicked = new EventEmitter<any>()

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {    
  }

  clickItem(item: Conversation) {
    this.router.navigate(['/conversation', item.name, 
      { 
        backLabel: this.backLabel || '', 
        launchSource: 'Resources' 
      }])
  }

  clickCategory(category: any) {
    this.categoryClicked.emit(category)
  }

  isOpened(category: any) {
    return this.opened.includes(category.id)
  }
}
