 <div id="entryCard" class="insta-entry-card">
  <ion-item-sliding #slidingItem>
    <ion-item lines="none">
      <ion-grid>
        <ion-row class="top-row">
          <ion-col size="7">
            <span class="rounded-type" [style.color]="entrySpec.backgroundColor || 'black'">
              {{ displayType || entry.subtype || entry.type }} 
              {{ entry.hasWarning() ? '⚠️' : ''}}
            </span>
          </ion-col>
          <ion-col size="5" class="timestamp">
            {{ entry.updatedAt | isoDateStr | dfnsFormatDistanceToNow: { addSuffix: true } }}
          </ion-col>
        </ion-row>
        <ion-row 
          class="entry-display-row"
          (click)="viewEntry()">
          <ion-col size="12">
            {{ entry.displayName }}
          </ion-col>
        </ion-row>
        <ion-row *ngIf="entry.isUnapproved()" class="icons-row">
          <ion-col size="9" class="icons-col" style="padding: 0 0 8px 0; font-size: 12px; font-weight: bold; color: var(--chea-purple)">
            Pending
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!entry.isUnapproved()" class="icons-row">
          <ion-col size="9" class="icons-col">
            <div class="left-icons">
              <mat-icon data-testid="shareButton" [matMenuTriggerFor]="shareMenu">
                ios_share_outline
              </mat-icon>
              <mat-menu #shareMenu="matMenu" yPosition="below" xPosition="after" class="entry-share-menu">
                <ng-template matMenuContent>
                  <button mat-menu-item (click)="shareEntry()">
                    <mat-icon>image_outline</mat-icon>
                    <span>Share Entry Image</span>
                  </button>
                  <button mat-menu-item (click)="entryService.shareLink(entry)">
                    <mat-icon>link_outline</mat-icon>
                    <span>Share Link on Web</span>
                  </button>
                  <button mat-menu-item (click)="entryService.launchWebEntryLink(entry)">
                    <mat-icon>link</mat-icon>
                    <span>Preview Link on Web</span>
                  </button>                  
                </ng-template>
              </mat-menu>
              <ng-container *ngIf="collectible">
                <div *ngIf="collectionCounts[entry.docId] || '0' as collectionCount">
                  <mat-icon
                    data-testid="playlist_add_button"
                    [style.color]="collectionCount > 0 ? 'var(--chea-purple)' : 'darkgray'"
                    (click)="addToCollection()">
                    playlist_add
                  </mat-icon>
                  <span class="badge-span">{{ collectionCount }}</span>
                </div>  
              </ng-container>
              <div *ngFor="let act of entrySpec.actionLinks">
                <div class="action-link" *ngIf="(childCounts[entry.docId] ? childCounts[entry.docId][act.actionEntrySpec.name] : null) || '0' as actionCount">                  
                  <mat-icon 
                    id="actionButton"
                    [style.color]="actionCount > 0 ? 'var(--chea-purple)' : 'darkgray'"
                    (click)="clickAction(act)">
                    {{ act.icon }}
                  </mat-icon>
                  <span class="badge-span">{{ actionCount }}</span>
                  <!-- <ion-badge>{{ actionCount }}</ion-badge>  -->
                </div>
              </div>
              <ion-icon 
                *ngIf="favoritable"
                id="heart"
                class="favorite-icon"
                [style.color]="favorites.has(entry.docId) ? 'var(--chea-purple)' : 'gray'" 
                name="heart-outline" 
                (click)="toggleFavorite()">
              </ion-icon>                          
            </div>
          </ion-col>
          <ion-col class="rightmost-col" [matMenuTriggerFor]="beforeMenu">
            <ion-icon mat-icon-button id="ellipsisMenu" size="small" name="ellipsis-horizontal"></ion-icon>
            <mat-menu #beforeMenu="matMenu" yPosition="below">
              <ng-template matMenuContent>
                <button *ngIf="collectible" mat-menu-item (click)="addToCollection()">
                  <mat-icon>playlist_add</mat-icon>
                  <span>Add to Collection</span>
                </button>
                <button *ngIf="favoritable && !favorites.has(entry.docId)" mat-menu-item (click)="addToFavorites()">
                  <mat-icon>favorite_border</mat-icon>
                  <span>Add to Favorites</span>
                </button>
                <button *ngIf="favoritable && favorites.has(entry.docId)" mat-menu-item (click)="removeFromFavorites()">
                  <mat-icon>favorite</mat-icon>
                  <span>Remove from Favorites</span>
                </button>                
                <ng-container *ngFor="let act of entrySpec.actionLinks">
                  <button mat-menu-item (click)="clickAction(act)">
                    <mat-icon >{{ act.icon || 'thumb_up' }}</mat-icon>
                    <span>{{ act.name }}</span>
                  </button>
                </ng-container>
                <ng-container *ngFor="let act of extraActions">
                  <button mat-menu-item (click)="clickExtraAction(act)">
                    <mat-icon>{{ act.icon || 'thumb_up' }}</mat-icon>
                    <span>{{ act.name }}</span>
                  </button>
                </ng-container>
                <mat-divider *ngIf="entrySpec.actionLinks.length > 0 || extraActions.length > 0"></mat-divider>
                <button mat-menu-item (click)="editEntry()">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="confirmDelete()">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </ng-template>
            </mat-menu>  
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
    <ion-item-options side="start">
      <ion-item-option *ngIf="collectible && !entry.isUnapproved()" color="medium" (click)="addToCollection(); slidingItem.close()">
        Add to Collection
      </ion-item-option>
      <ng-container *ngFor="let act of entrySpec.actionLinks">
        <ion-item-option color="medium" (click)="clickAction(act); slidingItem.close()">
          {{ act.name }}
        </ion-item-option>                    
      </ng-container>
      <ng-container *ngFor="let act of extraActions">
        <ion-item-option color="medium" (click)="clickExtraAction(act); slidingItem.close()">
          {{ act.name }}
        </ion-item-option>            
      </ng-container>
    </ion-item-options>
    <ion-item-options side="end">
      <ion-item-option *ngIf="!entry.isUnapproved()" color="medium" (click)="editEntry(); slidingItem.close()">
        Edit
      </ion-item-option>
      <ion-item-option *ngIf="!entry.isUnapproved()" color="medium" (click)="confirmDelete(); slidingItem.close()">
        Delete
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
 </div>
