<div class="chat-feed-item">
    <ion-item detail="true" lines="none" class="feed-item" (click)="clickFeedItem(state)">
        <div class="details-wrapper">
            <h2 class="title" style="margin: 2px">{{ state?.title }}</h2>
            <p class="subtitle" style="margin: 2px">
                {{ state?.subtitle }} 
                <ion-icon 
                    *ngIf="state?.showHelp"
                    name="help-circle-outline" 
                    style="vertical-align: top; font-size: 24px;" 
                    (click)="clickHelp(state); $event.stopPropagation();"></ion-icon>
            </p>
        </div>
    </ion-item>
</div>
<div style="height: 20px"></div>  

<ion-modal 
    [isOpen]="isPreviewOpen" 
    style="--height: 360px; --width: 360px;"
    canDismiss="true"
    (didDismiss)="isPreviewOpen = false">
    <ng-template>
        <img [src]="previewUrl" (click)="isPreviewOpen = false">                 
        <!-- <ion-content>
            <div style="text-align:right; padding: 16px 16px 0 16px;" (click)="isPreviewOpen = false">
                <ion-icon name="close"></ion-icon>
            </div>
            <img [src]="previewUrl" (click)="isPreviewOpen = false">                 
        </ion-content> -->
    </ng-template>
</ion-modal>