<ng-container *ngIf="data$ | async as data">
  <div #reportCard id="reportCard" [class]="'entry-report-card' + (parent ? ' child-card' : ' parent-card')">
    <ion-item-sliding #slidingItem>
      <ion-item lines="none" detail="false" (click)="openForm$.next(data.entry.docId)">
        <ion-grid>
          <ion-row>
            <ion-col size="11" class="cardtitle">
              <div *ngIf="data.parentIsCollection" style="padding-bottom: 8px;">
                <ion-chip outline [style]="'--color: ' + (data.backgroundColor || 'black')">
                  <ion-label style="font-weight: bold;">{{ data.entryTypeName }}</ion-label>
                </ion-chip>                
                <ion-icon *ngIf="isFavorited"
                  class="favorite-icon"
                  name="heart-outline">
                </ion-icon>  
              </div>
              <div *ngIf="!parent || data.parentIsCollection" [class]="data.entry.isCollectionType() ? 'collectionTitle' : 'displayTitle'">
                  {{ data.entry.displayName || contentService.getAction(data.entry.subtype)?.description || data.entry.type }}
              </div>
              <ion-chip *ngIf="parent && !data.parentIsCollection" outline="true">
                <ion-label>{{ data.entryTypeName }}</ion-label>
              </ion-chip>
            </ion-col>
            <ion-col *ngIf="!parent && !readonly" size="1'" class="ion-text-end ion-no-padding" style="align-self: start">
              <ion-icon id="pencil" style="font-size: 22px" src="assets/icon/create-24px.svg"></ion-icon>
            </ion-col>
            <ion-col 
              *ngIf="parent && !readonly"
              size="1" 
              class="ion-text-end ion-no-padding"
              [style.align-self]="data.parentIsCollection ? 'start' : 'center'"
              (click)="$event.stopPropagation()">
              <ion-icon id="ellipsisMenu" name="ellipsis-horizontal" [matMenuTriggerFor]="beforeMenu"></ion-icon>
              <mat-menu #beforeMenu="matMenu" yPosition="below">
                <ng-template matMenuContent>
                  <button mat-menu-item (click)="openForm$.next(data.entry.docId)">
                    Edit
                  </button>
                  <button mat-menu-item (click)="confirmRemove(data.entry)">
                    {{ data.parentIsCollection ? 'Remove from Collection' : 'Delete' }}
                  </button>  
                </ng-template>
              </mat-menu>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="data.displayableAttributes.length > 0">
            <ng-container *ngIf="!data.entry.isCollectionType(); else collectionDescription">
              <ion-col>
                <ng-container *ngIf="data.longStyle; else shortStyle">
                  <ion-grid class="attributes-grid">
                    <ng-container *ngFor="let attr of data.displayableAttributes">
                      <ng-container *ngIf="attr.value && attr.attributeName !== 'name'">
                        <ion-row>
                          <ion-col size="12" class="question">
                            <div [innerHtml]="data.questions[attr.attributeName]" style="padding-right: 8px; margin: 0;"></div>
                          </ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-col size="12" class="response">
                            {{ data.responses[attr.attributeName] }}
                          </ion-col>
                        </ion-row>
                      </ng-container>
                    </ng-container>
                  </ion-grid>
                </ng-container>
                <ng-template #shortStyle>
                  <ion-grid class="attributes-grid">
                    <ng-container *ngFor="let attr of data.displayableAttributes">
                      <ion-row *ngIf="attr.value && attr.attributeName !== 'name'">
                        <ion-col size="4" class="question">
                          <div [innerHtml]="data.questions[attr.attributeName]" style="padding-right: 8px; margin: 0;"></div>
                        </ion-col>
                        <ion-col size="8" class="question">
                          {{ data.responses[attr.attributeName] }}
                        </ion-col>
                      </ion-row>
                    </ng-container>
                  </ion-grid>
                </ng-template>
              </ion-col>  
            </ng-container>
            <ng-template #collectionDescription>
              <ion-col class="collectionDescription">
                {{ data.responses['description'] }}
              </ion-col>
            </ng-template>
          </ion-row>
          <ion-row>
            <ion-col size="12" class="timestamp">
              {{ data.entry.updatedAt | isoDateStr | dfnsFormatDistanceToNow: { addSuffix: true } }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item-options side="end">
        <ion-item-option *ngIf="!readonly" (click)="openForm$.next(data.entry.docId); slidingItem.close()">
          Edit
        </ion-item-option>
        <ion-item-option *ngIf="!readonly" (click)="confirmRemove(data.entry); slidingItem.close()">
          {{ data.parentIsCollection ? 'Remove from Collection' : 'Delete' }}
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </div>
</ng-container>