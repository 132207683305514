<div [formGroup]="entryFormGroup">
    <div [ngSwitch]="attributeSpec.inputType">
        <input *ngSwitchCase="'STATIC'" [formControlName]="attributeSpec.attributeName">
        <!-- text -->
        <mat-form-field [id]="questionText" *ngSwitchCase="'TEXT'" [appearance]="fieldAppearance" floatLabel="always">
          <mat-label [innerHtml]="questionText"></mat-label>
            <!-- setSelectionRange below ensures that the cursor does not jump to the end of the field, as seen on iOS -->
            <input matInput
                (input)="inputFieldSubject.next({ id: attributeSpec.attributeName, event: $event })"
                [placeholder]="attributeSpec.placeholder" 
                [formControlName]="attributeSpec.attributeName"                       
                [required]="attributeSpec.isRequired || null">
            <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
      <!-- textarea -->
      <!-- <ion-textarea *ngSwitchCase="'TEXTAREA'" rows="10" autoGrow="true" placeholder="{{ attributeSpec.placeholder }}" formControlName="{{ attributeSpec.attributeName }}"></ion-textarea> -->
      <mat-form-field id="{{ attributeSpec.name }}" *ngSwitchCase="'TEXTAREA'" [appearance]="fieldAppearance" floatLabel="always">
        <mat-label [innerHtml]="questionText"></mat-label>
        <textarea matInput
          rows="5" 
          autoGrow="true" 
          (input)="selectionChanged(attributeSpec.attributeName, $event)"
          [placeholder]="attributeSpec.placeholder" 
          [formControlName]="attributeSpec.attributeName"
          [required]="attributeSpec.isRequired || null">
        </textarea>
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
      <!-- date -->      
      <mat-form-field id="{{ attributeSpec.name }}" *ngSwitchCase="'DATE'" [appearance]="fieldAppearance" floatLabel="always">
          <input matInput
            [matDatepicker]="picker" 
            [formControlName]="attributeSpec.attributeName" 
            (dateChange)="onDateChangeEvent(attributeSpec.attributeName, $event)"
            [required]="attributeSpec.isRequired || null">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-label [innerHtml]="questionText"></mat-label>
      </mat-form-field>
      <!-- options -->
      <ng-container *ngSwitchCase="'OPTIONS'">
        <mat-form-field id="{{ attributeSpec.name }}" [appearance]="fieldAppearance" floatLabel="always">
          <mat-select             
              formControlName="{{ attributeSpec.attributeName }}" 
              (selectionChange)="selectionChanged(attributeSpec.attributeName, $event)"
              [required]="attributeSpec.isRequired || null">
              <ng-container *ngIf="dynamicOptions.length > 0; else elseBlock">
                <mat-option *ngFor="let item of dynamicOptions" [value]="item.id">{{ item.name }}</mat-option>
              </ng-container>
              <ng-template #elseBlock>
                <mat-option *ngFor="let item of attributeSpec.optionLinks" [value]="item.name">{{ item.description }}</mat-option>
              </ng-template>
            </mat-select>
          <mat-label [innerHtml]="questionText"></mat-label>
        </mat-form-field>
    <!-- 
          <ng-container *ngIf="attributeSpec.optionLinks.length < 4; else elseblock2">
            <ion-segment formControlName="{{ attributeSpec.attributeName }}">
              <ion-segment-button *ngFor="let item of attributeSpec.optionLinks"  value="{{item.name}}" class="option-segment-button">
                  {{ item.description }}
              </ion-segment-button>
            </ion-segment>
          </ng-container>
          <ng-template #elseblock2>
            <ion-select interface="popover" formControlName="{{ attributeSpec.attributeName }}" (ionChange)="selectionChanged($event)" [interfaceOptions]="popoverOptions">
                <ion-select-option *ngFor="let item of attributeSpec.optionLinks"  value="{{item.name}}">
                      {{ item.description }}
                </ion-select-option>
            </ion-select>
          </ng-template> -->
        <!-- </ng-template> -->
      </ng-container>
      <!-- options -->
      <mat-form-field id="{{ attributeSpec.name }}" *ngSwitchCase="'COMBOBOX'" [appearance]="fieldAppearance" floatLabel="always">
        <mat-select 
            [placeholder]="attributeSpec.placeholder"
            [formControlName]="attributeSpec.attributeName" 
            (selectionChange)="comboboxChanged(attributeSpec.attributeName, $event)"
            [required]="attributeSpec.isRequired || null">
          <mat-option *ngFor="let obj of attributeSpec.choicelist" [value]="obj">{{ obj }}</mat-option>
          <mat-option value="Other">Add New</mat-option>
        </mat-select>
        <mat-label [innerHtml]="questionText"></mat-label>
      </mat-form-field>
      <!-- multi options -->
      <div *ngSwitchCase="'MULTIOPTIONS'" class="outlined-question">
        <span class="question-label">
          <span [innerHtml]="questionText + ( attributeSpec.isRequired ? ' *' : '' )"></span>
        </span>
        <ion-item-group id="{{ attributeSpec.name }}">
          <ion-item *ngFor="let opt of attributeSpec.optionLinks" lines="none">
            <ion-checkbox slot="start" [checked]="opt.checked" (ionChange)="checkboxClicked(opt, $event)"></ion-checkbox>
            <span style="white-space: normal">{{opt.description}}</span>
          </ion-item>
        </ion-item-group>
      </div>
      <!-- integer scale -->
      <!-- <ion-range *ngIf="attributeSpec.inputType === 'SCALE'" min="1" [max]="attributeSpec.scaleMax" 
          step="1" snaps="true" pin="true" color="primary" 
          formControlName="{{ attributeSpec.attributeName }}">
        <ion-icon size="small" slot="start" name="sunny"></ion-icon>
        <ion-icon slot="end" name="sunny"></ion-icon>
      </ion-range> -->
      <div *ngSwitchCase="'SEGMENTED'" class="outlined-question">
        <span class="question-label">
          <span>{{ questionText + ( attributeSpec.isRequired ? " *" : "" ) }}</span>
        </span>
        <div class="outlined-response">
          <ion-segment ngDefaultControl id="{{ attributeSpec.name }}" formControlName="{{ attributeSpec.attributeName }}" (ionChange)="selectionChanged(attributeSpec.attributeName, $event)">
            <ion-segment-button 
              *ngFor="let item of attributeSpec.optionLinks"
              value="{{ item.name }}" 
              class="scale-segment-button"
              layout="icon-start">
              <ion-label class="scale-label">{{ item.description }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </div>
      </div>
      <div *ngSwitchCase="'SCALE'" class="outlined-question">
        <span class="question-label">
          <span>{{ questionText + ( attributeSpec.isRequired ? " *" : "" ) }}</span>
        </span>
        <div class="outlined-response">
          <ion-segment ngDefaultControl id="{{ attributeSpec.name }}" formControlName="{{ attributeSpec.attributeName }}" (ionChange)="selectionChanged(attributeSpec.attributeName, $event)">
            <ion-segment-button 
              *ngFor="let i of attributeSpec.scaleRange" 
              value="{{i}}" 
              class="scale-segment-button"
              layout="icon-start">
              <!-- <ion-icon size="small" style="color: #e7b376" name="{{ segmentIcons[i - 1] ? 'radio-button-on' : 'radio-button-off' }}"></ion-icon> -->
              <!-- <ion-icon size="small" [style.color]="[ '#e7b376', '#a9d1b8', '#00a583' ][i - 1]" name="radio-button-on"></ion-icon> -->
              <!-- <ion-icon size="small" [style.color]="[ '#e7b376', '#a9d1b8', '#00a583', '#e7b376', '#a9d1b8', '#00a583' ][i - 1]" src="assets/icon/circle.svg"></ion-icon> -->
              <ion-label class="scale-label">{{ attributeSpec.scaleLabels[i - 1] }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </div>
      </div>
      <div *ngSwitchCase="'HOUROFDAY'" class="outlined-question">
        <span class="question-label">
            <span>{{ questionText + ( attributeSpec.isRequired ? " *" : "" ) }}</span>
        </span>
        <ion-datetime 
            [formControlName]="attributeSpec.attributeName" 
            presentation="time" 
            minuteValues="0"
            placeholder="Select a time" 
            (ionChange)="selectionChanged(attributeSpec.attributeName, $event)">
        </ion-datetime>
      </div>
      <ion-grid *ngSwitchCase="'VIDEOCAPTURE'" style="padding: 0px 0px 20px 0px">
          <ion-row>
            <ion-col>
              <input matInput [formControlName]="attributeSpec.attributeName" hidden="true"/>
              <ion-button (click)="selectMedia()">
                <ion-icon slot="start" size="large" name="videocam"></ion-icon>
                Record Video
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="videoUrl">
            <ion-col size="12" style="height: 300px">
              <vg-player (onPlayerReady)="onPlayerReady($event)">
                <video #media [vgMedia]="$any(media)" id="singleVideo" preload="auto" controls>
                  <source *ngFor="let video of [ videoUrl ]" [src]="video" type="video/mp4">
                </video>
              </vg-player>
            </ion-col>
          </ion-row>
      </ion-grid>
      <div *ngSwitchCase="'ENTRYSELECTOR'" class="outlined-question">
        <span class="question-label">
          <span [innerHtml]="questionText + ( attributeSpec.isRequired ? ' *' : '' )"></span>
        </span>
        <div [id]="attributeSpec.name" class="outlined-response">
          <ng-container *ngIf="attributeSpec.inputSubtype; else collection">
            <cheaseed-entry-selector 
            [subtype]="attributeSpec.inputSubtype" 
            [selected]="selectedEntries" 
            [buttonTitle]="attributeSpec.shortQuestion"
            (selectionChanged)="selectorChanged($event)">
          </cheaseed-entry-selector>
          </ng-container>
          <ng-template #collection>
            <cheaseed-collection-selector 
              [selected]="selectedEntries" 
              (collectionChanged)="selectorChanged($event)">
            </cheaseed-collection-selector>
          </ng-template>  
        </div>
      </div>
      <mat-form-field *ngSwitchCase="'XSPINNER'" [appearance]="fieldAppearance">
        <mat-label>{{ questionText }}</mat-label>
        <input type="number"
            min="0" 
            max="10"
            matInput
            [formControlName]="attributeSpec.attributeName" 
            [required]="attributeSpec.isRequired || null">
      </mat-form-field>
      <ion-grid *ngSwitchCase="'YSPINNER'" class="ion-no-padding">
        <ion-row>
          <ion-col size="8">
              <ion-label>{{ questionText }}</ion-label>
          </ion-col>
          <ion-col size="4">
          <cheaseed-counter-input [formControlName]="attributeSpec.attributeName" [min]="1" [max]="10"></cheaseed-counter-input>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div *ngSwitchCase="'RADIOCHIPS'" class="outlined-question">
        <span class="question-label">
          <span [innerHtml]="questionText + ( attributeSpec.isRequired ? ' *' : '' )"></span>
        </span>
        <div [id]="attributeSpec.name" class="outlined-response">
          <ion-chip 
            *ngFor="let item of attributeSpec.optionLinks"
            class="{{ entryFormGroup.value[attributeSpec.attributeName] === item.name ? 'chip-selected' : 'chip-unselected' }}"
            (click)="setChip(item)">
            <ion-label>{{ item.description }} </ion-label>
          </ion-chip>
        </div>
      </div>
  </div>