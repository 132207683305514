import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cheaseed-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  @Input() backRef = "/tabs/home"

  constructor() { }

  ngOnInit(): void {
  }

}
