import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { FormService } from "@cheaseed/cheaseed-core"

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-blockentryform',
  templateUrl: './blockentryform.component.html',
  styleUrls: ['./blockentryform.component.scss'],
})
export class BlockentryformComponent implements OnInit, OnDestroy {
  @Input() entrySpec: any
  @Input() attributes: any
  @Input() defaults: any[]
  // @Input() requireBlocks?: string[]
  @Input() parentEntryId: string  // TODO
  @Input() feedback = false  // true if attributes are for feedback
  @Input() readonly = false // true if blocks should all be disabled for editing
  // @Output() formChanged = new EventEmitter<boolean>()  // only emitted if !readonly and something changed

  loading = false
  attributeSpecsMap: Map<string, any>
  attributeMap: Map<string, any>
  entryFormGroup: UntypedFormGroup
  inputBlocks: any[]
  hasAllRequiredFields = false

  constructor(private formService: FormService) {}

  ngOnInit() {
    // logger.log("blockentryform ngOnInit")
    this.reload()
  }

  ngOnDestroy() {
    // logger.log("blockentryform ngOnDestroy")
  }
  
  async reload() {
    this.loading = true
    this.attributeSpecsMap = this.formService.getAttributeSpecsMap(this.entrySpec.attributeSpecs)    
    // attributeMap contains actual entry attributes or shadow attributes
    this.attributeMap = new Map(Object.entries(this.attributes).map(a => [ a[0], { value: a[1] } ] ))

    this.attributeSpecsMap.forEach((v, k) => {
      let attr = this.attributeMap.get(k)
      if (!attr) {
        attr = { shadow: true }
        this.attributeMap.set(k, attr)
      }
    })
    // Prepare attributes to display
    this.inputBlocks = this.formService.computeBlocks(this.attributeSpecsMap)
    this.formService.fillAttributes(this.attributeSpecsMap, this.attributeMap)
    this.formService.evaluateForCapture(
      this.attributeSpecsMap,
      this.attributeMap,
      null,
      this.inputBlocks)
    // Set first visible block to open
    const b = this.inputBlocks.find(b => !b.hidden)
    if (b) b.state = true

    // Assign FormGroup controls
    const controls = this.formService.assignFormControls(this.attributeSpecsMap, this.attributeMap, this.readonly)
    this.entryFormGroup = this.formService.populateEntryForm(controls, this.attributeSpecsMap, this.attributeMap)
    if (this.defaults) {
      console.log("blockentryform detected defaults", this.entryFormGroup, this.defaults, this.entrySpec, this.attributes)
      this.entryFormGroup.markAsDirty()
      this.evaluateRequired(this.defaults)
    }
    this.loading = false
  }
 
  // Call from container to actually save, or alert/toast
  // import { BlockentryformComponent } from '../components/blockentryform/blockentryform.component';
  // @ViewChild(BlockentryformComponent) blockForm: BlockentryformComponent;
  shouldSave() {
    return this.formService.shouldSave(this.entryFormGroup, this.attributeSpecsMap, this.defaults)
  }

  // Call from container
  markAsPristine() {
    this.entryFormGroup.markAsPristine()
  }
  
  // Call from container
  getAttributeValue(attributeName) {
    return this.attributeMap.get(attributeName).value
  }

  // Call from container
  getEntryFormGroup() {
    return this.entryFormGroup
  }
  readyToSubmit() {
    return this.entryFormGroup.dirty && this.hasAllRequiredFields
  }

  isAttributeRequired(attr: any): boolean {
    return attr.isRequired // || (this.requireBlocks || []).includes(attr.inputBlockName)
  }

  evaluateRequired(values: any[]) {
    this.hasAllRequiredFields = 
      this.formService.hasAllRequiredFields(this.entrySpec, values)
  }

  fieldChanged(payload?:any) {
    if (payload) {
      this.formService.evaluateForCapture(
        this.attributeSpecsMap,
        this.attributeMap,
        payload.values,
        this.inputBlocks)
      this.evaluateRequired(payload.values)
    }
  }

}

// TODO:
// 1. Componentize sectionentryform: move attribute, inputblock mgmt to component blockentryform 
// 2.    pass in entrySpec, attributes, readonly flag
// 3.    compute inputblocks
// 4.    allow ViewChild access to check for changes and validity before saving in container
// 5. Called from sectionentryform, supporterrequest, supporterresponse page
// 6.    supporterrequest displays entry readonly, feedback readonly
// 7.    supporterresponse displays entry readonly, feedback writable
// 8.    sectionentryform is entry writable

