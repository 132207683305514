import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChallengeState, ContentService, HandlebarsService, SharedChallengeService } from '@cheaseed/cheaseed-core';
import { ChallengeSpec } from '@cheaseed/node-utils';
import { ModalController } from '@ionic/angular';
import { ChallengeSelectorComponent } from '../challenge-selector/challenge-selector.component';

@Component({
  selector: 'cheaseed-challenges-browser',
  templateUrl: './challenges-browser.component.html',
  styleUrls: ['./challenges-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChallengesBrowserComponent implements OnInit {

  readonly ChallengeState = ChallengeState
  
  @Input() selectedType = ChallengeState.Current

  constructor(
    private router: Router,
    public contentService: ContentService,
    public challengeService: SharedChallengeService,
    private modalController: ModalController,
    public handlebars: HandlebarsService,
  ) { }
  
  ngOnInit(): void {
  }

  async clickAddChallenge(challengeType: string, availables: ChallengeSpec[]) {
    const modal = await this.modalController.create({
      component: ChallengeSelectorComponent,
      cssClass: 'program-selector-modal',
      componentProps: {
        challengeType,
        availables
      }
    });
    modal.onDidDismiss()
      .then((result:any) => {
        // console.log(result)
        if (result.data?.selectedChallenge) {
          const c:ChallengeSpec = result.data?.selectedChallenge
          if (c.behaviors?.includes("Public"))
            this.router.navigate([ 'challenge-public-join', c.name ])
          else 
            this.router.navigate([ 'challenge-form', { name: result.data.selectedChallenge.name } ])
        }
      })
    return await modal.present();
  }

}


