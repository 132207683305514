import { Component, OnInit, forwardRef, Input, OnChanges, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

export function counterRangeValidator(minValue: number, maxValue: number) {
  return (c: UntypedFormControl) => {
    const err = {
      rangeError: {
        given: c.value,
        min: minValue || 0,
        max: maxValue || 10
      }
    };

    return (c.value > +maxValue || c.value < +minValue) ? err : null;
  };
}

@Component({
  selector: 'cheaseed-counter-input',
  templateUrl: './counter-input.component.html',
  styleUrls: ['./counter-input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CounterInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => CounterInputComponent), multi: true }
  ],
  encapsulation: ViewEncapsulation.None
})
export class CounterInputComponent implements ControlValueAccessor, OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input('counterValue') _counterValue = 0
  // tslint:disable-next-line:no-input-rename
  @Input('max') counterRangeMax = 0
  // tslint:disable-next-line:no-input-rename
  @Input('min') counterRangeMin = 0
  @Output() counterChanged = new EventEmitter<any>()

  propagateChange: any = () => {}; // Noop function
  validateFn: any = () => {}; // Noop function

  get counterValue() {
    // console.log("getting counter", this._counterValue)
    return this._counterValue;
  }

  set counterValue(val) {
    // console.log("setting counter", val)
    this._counterValue = val
    // console.log("about to emit")
    this.counterChanged.emit( { value: val } )
    this.propagateChange(val);
  }

  ngOnChanges(inputs) {
    // console.log("ngOnChanges counter", this._counterValue)
    if (inputs.counterRangeMax || inputs.counterRangeMin) {
      this.validateFn = counterRangeValidator(this.counterRangeMin, this.counterRangeMax);
    }
  }

  writeValue(value) {
    // console.log("ngOnChanges writeValue", value)
    this.counterValue = value ?? 0
  }

  registerOnChange(fn) {
    // console.log("registerOnChange counter", fn)
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  increase() {
    if (this.counterValue + 1 <= this.counterRangeMax)
      this.counterValue++;
  }

  decrease() {
    if (this.counterValue - 1 >= this.counterRangeMin)
      this.counterValue--;
  }

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
}
