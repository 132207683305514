import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { HandlebarsService, ContentService } from '@cheaseed/cheaseed-core';
import { map } from 'rxjs';

@Component({
  selector: 'cheaseed-instruction-card',
  templateUrl: './instruction-card.component.html',
  styleUrls: ['./instruction-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstructionCardComponent {

  @Input() instructions: SafeHtml|null = null;
  @Input() key: string|null = null;

  private handlebars = inject(HandlebarsService)
  private contentService = inject(ContentService)

  data$ = this.contentService.isLoaded$
    .pipe(
      map(() =>
        this.key 
          ? this.handlebars.format(this.contentService.getGlobal(this.key))
          : (this.instructions || "No instructions provided.")
      )
    )
}
