import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser'
import { UtilityService } from '@cheaseed/cheaseed-core';
import { nowstr } from '@cheaseed/node-utils';

@Component({
  selector: 'cheaseed-statement-resources',
  templateUrl: './statement-resources.component.html',
  styleUrls: ['./statement-resources.component.scss']
})
export class StatementResourcesComponent implements OnInit {

  @Input() statement: any;
  @Input() eventService: any;
  @Input() userService: any;
  numResources = 0
  
  constructor(
    private modalController: ModalController,
    private utilityService: UtilityService,
    private platform: Platform
  ) { }

  ngOnInit(): void {
    // count items
    let cnt = 0
    this.statement.areas.forEach((area:any) => {
      cnt += area.items.length
      })
    this.numResources = cnt
  }

  cancel() {
    this.modalController.dismiss()
  }

  async openExternal(item: any) {
    this.eventService.recordDrawerSelectSource({
      id: this.statement.name,
      sourceId: item.sourceId,
      sourceType: item.sourceType,
      sourceTitle: item.title,
      sourceUrl: item.url
    })
    if (this.platform.is("capacitor"))
      await Browser.open({ url: item.url });
    else
      window.open(item.url, '_blank')

    // Collect feedback on external URL
    await this.utilityService.multiconfirm({
      header: `Was this resource useful?`,
      message: 'Help us improve our links to resources on the web.',
      cssClass: 'link-feedback-dialog',
      buttons: [
        {
          text: '👍 Yes',
          handler: () => this.processLinkFeedback(item, 0)
        },
        {
          text: '👎 No',
          handler: () => this.processLinkFeedback(item, 1)
        },
        {
          text: '💔 Broken?',
          handler: () => this.processLinkFeedback(item, 2)
        }
      ]})
  }

  processLinkFeedback(item:any, feedback: number) {
    this.userService.putUserLinkFeedback({
      createdAt: nowstr(),
      sourceId: item.sourceId,
      sourceType: item.sourceType,
      sourceTitle: item.title,
      sourceUrl: item.url,
      broken: feedback == 2,
      useful: feedback == 0,
    })
    // If there is more than one link to display, do not cancel the dialog
    if (this.numResources === 1)
      this.cancel()
  }
}
