import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { HIDDEN, formatDate } from '@cheaseed/node-utils'
import { EntryService, EvaluatorService, FormService, NOTNOW_ID, REMINDME_ID, SharedUserService } from "@cheaseed/cheaseed-core"

const logger = console

@Component({
  selector: 'cheaseed-entry-block',
  templateUrl: './entry-block.component.html',
  styleUrls: ['./entry-block.component.scss'],
})
export class EntryBlockComponent implements OnInit, OnDestroy {
  @Input() entrySpec: any
  @Input() attributes: any
  @Input() defaults: any
  @Input() parentEntryId = ''  // TODO
  @Input() feedback = false  // true if attributes are for feedback
  @Input() readonly = false // true if blocks should all be disabled for editing
  // @Output() formChanged = new EventEmitter<boolean>()  // only emitted if !readonly and something changed

  loading = false
  attributeSpecsMap: Map<string, any> = new Map()
  attributeMap: Map<string, any> = new Map()
  entryFormGroup: UntypedFormGroup
  inputBlocks: any[] = []
  hasAllRequiredFields = false

  constructor(private userService: SharedUserService,
              public formBuilder: UntypedFormBuilder,
              private formService: FormService,
              private evaluatorService: EvaluatorService,
              private entryService: EntryService,
              ) {}

  ngOnInit() {
    // logger.log("blockentryform ngOnInit")
    this.reload()
  }

  ngOnDestroy() {
    // logger.log("blockentryform ngOnDestroy")
    null
  }
  
  async reload() {
    //logger.log("Blockentryform reload")
    //logger.log("entering with attributes", this.attributes)
    //logger.log("entering with entrySpec", this.entrySpec)
    //logger.log("entering with readonly", this.readonly)
    this.loading = true
    this.attributeSpecsMap = new Map(this.entrySpec.attributeSpecs
          .filter(a => (this.feedback && a.isFeedback) || (!this.feedback && !a.isFeedback))
          .map(a => [a.attributeName, a]))
    
    // attributeMap contains actual entry attributes or shadow attributes
//     console.log('attributeMap', this.attributeMap)
    this.attributeMap = new Map(Object.entries(this.attributes).map(a => [ a[0], { value: a[1] } ] ))
//     console.log('attributeMap2', this.attributeMap)

    this.attributeSpecsMap.forEach((v, k) => {
      let attr = this.attributeMap.get(k)
      if (!attr) {
        attr = { shadow: true }
        this.attributeMap.set(k, attr)
      }
    })
//     console.log('attrSpecMap', this.attributeSpecsMap)
    // logger.log("attributes", this.attributes)
    // logger.log("attributeMap", this.attributeMap)
    // logger.log("attributeSpecsMap", this.attributeSpecsMap)
    // Prepare attributes to display
    const blocks = new Map()
    this.attributeSpecsMap.forEach((v, k) => {
      const b = blocks.get(v.inputBlockName)
      if (b)
        b.push(v)
      else
        blocks.set(v.inputBlockName, [ v ] )
    })
    this.inputBlocks = Array.from(blocks.keys()).map(b => { return { name: b, attributes: blocks.get(b), state: false, hidden: false }})
    this.fillAttributes()
    this.evaluateForCapture()
    // Set first visible block to open
    const b = this.inputBlocks.find(b => !b.hidden)
    if (b) b.state = true

    // Assign FormGroup controls
    const controls:any = {}
    this.attributeSpecsMap.forEach(a => {
      let val = this.attributeMap.get(a.attributeName).value
      if (a.attributeName === 'entry') {
        val = this.entryService.getDisplayNameForId(this.parentEntryId)
      }
      val = a.inputType === "MULTIOPTIONS" && val ? this.evaljson(val) : val
      if (a.inputType === "COMBOBOX") {
        let list = a.optionLinks.length > 0 ? a.optionLinks.map(o => o.description) : []
        const keys = this.userService.getUserKeyCombo(a.attributeName)
        list = list.concat(keys || [])
        a.choicelist = list.length > 0 ? list : (val ? [ val ] : [])
      }
      else if (a.inputType === 'ENTRYSELECTOR') {
        val = val || []
      }
      const fbv = this.readonly || a.behaviors?.includes("readonly") ? {value: val, disabled: true } : val
      controls[a.attributeName] = a.isRequired ? [fbv, Validators.required] : [fbv] // set initial default values here
    })
    // logger.log("controls", controls)
    this.entryFormGroup = this.formBuilder.group(controls)
    this.populateEntryForm()
    // logger.log("attributeSpecsMap", this.attributeSpecsMap)
    // logger.log("entryFormGroup", this.entryFormGroup)
    if (this.defaults) {
      // console.log("blockentryform detected defaults", this.entryFormGroup, this.defaults, this.entrySpec, this.attributes)
      this.entryFormGroup.markAsDirty()
      this.hasAllRequiredFields = this.entrySpec.attributeSpecs
        .filter((a:any) => a.isRequired)
        .reduce((valid:boolean, attr:any) => valid && !!this.defaults[attr.attributeName], true)
    }
    this.loading = false
  }

  populateEntryForm() {
    const patch = {}
    this.attributeSpecsMap.forEach(a => {
      const obj = Object.assign({}, a)
      const attr = this.attributeMap.get(a.attributeName)
      
      if (!attr.value) {
        this.formService.populateDefault(obj, patch)
        attr.value = obj.value
      }
    })

    // Apply patch
    if (Object.keys(patch).length > 0) {
      this.entryFormGroup.patchValue(patch)
      // logger.log("Form Group Patched:", patch)
    }
  }
 
  evaljson(val:any) {
    try {
      return JSON.parse(val)
    }
    catch (err) {
      logger.error(err)
      return null
    }
  }

  fillAttributes() {
    this.attributeSpecsMap.forEach((attributeSpec, k) => {
      const key = attributeSpec.attributeName,
          attr = this.attributeMap.get(key)
      // OptionMap
      if(attributeSpec.optionLinks.length > 0) {
        attr.optionMap = {}
        attr.valueMap = {}
        attributeSpec.optionLinks.forEach((item:any) => {
          attr.optionMap[item.name] = item.description ?? 'MISSING'
        })
      }
      // Display value
      if (!attr.shadow && attr.value) {
        const value = attr.value
        if (['OPTIONS', 'SCALE'].includes(attributeSpec.inputType)) {
          attributeSpec.optionLinks.forEach((item:any) => {
            if (item.name === value) {
              attr.displayValue = item.description
              attr.value = value
            }
          })
        }
        else if (attributeSpec.inputType==='TEXTAREA') {
          attr.value = value
          attr.displayValue = value.replace(/\n/g,'<br>')
        }
        else {
          attr.value = value
        }
      }
    })
  }

  // Call from container to actually save, or alert/toast
  // import { BlockentryformComponent } from '../components/blockentryform/blockentryform.component';
  // @ViewChild(BlockentryformComponent) blockForm: BlockentryformComponent;
  shouldSave() {
    let mustSave = false
    const saveAttribs:any = {}
    for (const key of Object.keys(this.entryFormGroup.value)) {
      const control = this.entryFormGroup.controls[key]
      // console.log("shouldSave", key, mustSave, control)
      if (control) {
        const attr:any = this.attributeSpecsMap.get(key)
        let val:string|null = control.value
        if (typeof val === 'string' && val.length === 0) {
          logger.log(`Resetting val for ${key} to null`)
          val = null
        }
        if (attr.isRequired && !val) {
          return { save: false, missingRequired: true }
        }
        mustSave = mustSave || (control.dirty || this.defaults)
        if (attr.inputType === "MULTIOPTIONS")
          val = JSON.stringify(control.value)
        if (attr.inputType === "DATE" && control.value)
          val = formatDate(control.value)
        saveAttribs[key] = val
      }
    }
    return { save: mustSave, attributes: saveAttribs }
  }

  // Call from container
  markAsPristine() {
    this.entryFormGroup.markAsPristine()
  }
  
  // Call from container
  getAttributeValue(attributeName:string) {
    return this.attributeMap.get(attributeName).value
  }

  // Call from container
  getEntryFormGroup() {
    return this.entryFormGroup
  }
  readyToSubmit() {
    return this.entryFormGroup.dirty && this.hasAllRequiredFields
  }

  fieldChanged(payload?:any) {
    // logger.log("fieldChanged", payload)
    if (payload) {
      this.evaluateForCapture(payload.values)
      this.hasAllRequiredFields = this.entrySpec.attributeSpecs
        .filter(a => a.isRequired)
        .reduce((valid:boolean, attr:any) => valid && payload.values[attr.attributeName], true)
      // console.log("hasAllRequiredFields", this.hasAllRequiredFields)
    }
  }

  evaluateForCapture(valueMap?:{}) 
  {
    // create context for evaluator
    // keys in context must be simple attribute names (not Entity.AttributeName)
    const context:any = {}
    this.attributeSpecsMap.forEach(a => {
      context[a.attributeName] = valueMap ? valueMap[a.attributeName] : this.attributeMap.get(a.attributeName).value
    })
    const hidden:any = {}
    this.attributeSpecsMap.forEach(a => {
      const attr = this.attributeMap.get(a.attributeName)
      if (a.behaviors?.includes(HIDDEN)) // Always hide because spec says so
        attr.hidden = true
      else if (a.captureIf) // Check whether to capture
        attr.hidden = !this.evaluatorService.evaluate(a.captureIf, context)
      hidden[a.attributeName] = attr.hidden
    })
    for (const b of this.inputBlocks) {
      b.hidden = b.attributes.filter((a:any) => !hidden[a.attributeName]).length === 0
      // logger.log("block", b)
    }
  }
}
