<ion-card data-testid="reflect-pad" class="reflect-pad">
    <ion-card-header class="new-header">
      <div class="new-row">
        <div class="why-col">
            {{ initialMessage }}
        </div>
        <div class="buttons">
            <ion-grid>
                <ion-row style="margin-top: 5px">
                    <ion-col *ngFor="let item of shortcuts" [size]="12 / shortcuts.length">
                        <ion-label class="icon-circle">
                        <ion-icon *ngIf="item.icon_name"
                            [style.color]="item.color_expr() ? 'var(--chea-hotpink)' : 'black'"
                            size="small"
                            [name]="item.icon_name"
                            [routerLink]="item.route"
                            routerDirection="forward">
                        </ion-icon>
                        <mat-icon *ngIf="item.mat_icon_name" 
                            [routerLink]="item.route">
                            {{ item.mat_icon_name }}
                        </mat-icon>
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col *ngFor="let item of shortcuts" [size]="12 / shortcuts.length" class="inspiration-icon-label">
                        {{ item.label }}
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
      </div>
    </ion-card-header>
</ion-card>