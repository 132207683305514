<ng-container *ngIf="this.feedState$ | async as state">
    <div class="chat-feed-item">
        <ion-item detail="true" lines="none" class="feed-item" (click)="clickFeedItem(state)">
            <div class="details-wrapper">
                <h2 class="title" style="margin: 2px">{{ state.title }}</h2>
                <p class="subtitle" style="margin: 2px">{{ state.subtitle }}</p>
            </div>
        </ion-item>
    </div>
    <div style="height: 20px"></div>  
</ng-container>