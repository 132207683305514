import { Component, Input } from '@angular/core'
import { PromptStatusValue } from '@cheaseed/node-utils'

@Component({
  selector: 'cheaseed-prompt-status-button',
  templateUrl: './prompt-status-button.component.html',
  styleUrls: ['./prompt-status-button.component.scss'],
})
export class PromptStatusButtonComponent {

  @Input() promptStatus: PromptStatusValue | undefined = undefined

}
