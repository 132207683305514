import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, filter, map, shareReplay, tap, withLatestFrom } from 'rxjs';
import { ContentService, EntryService, UtilityService } from '@cheaseed/cheaseed-core';
import { BEHAVIOR_USE_LONG_REPORT_CARD_STYLE, Entry } from '@cheaseed/node-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cheaseed-entry-report-card',
  templateUrl: './entry-report-card.component.html',
  styleUrls: ['./entry-report-card.component.scss']
})
export class EntryReportCardComponent {

  entryService = inject(EntryService)

  @Input({ required: true }) entry!: Entry   // the entry to render in a report card
  @Input() parent: Entry|null = null   // null if not displaying a child
  @Input() isFavorited = false
  @Input() readonly = false
  @Input() isAdmin = false

  // Event for triggering form open
  openForm$ = new BehaviorSubject<string|null>(null)
  // Indicate whether card has been rendered
  rendered$ = new BehaviorSubject<boolean>(false)
  
  data$ = this.entryService.recentHistory$  // if recent change
    .pipe(
      filter(recents => !!recents && !!this.entry),
      withLatestFrom(this.rendered$, this.entryService.lastEntryUpdated$),
      filter(([recents, rendered, lastEntryId]) => !rendered || (rendered && this.entry.docId === lastEntryId)),
      // debounceTime(100),
      map(() => this.prepareData()),
      tap(data => {
        // console.log("entryreportcard data$", data)
        this.rendered$.next(true)
      }),
      shareReplay(1)
    )

  constructor(
    private router: Router,
    public contentService: ContentService,
    private utilityService: UtilityService,
    )
  { 
    // Handle openForm$ event
    this.openForm$
      .pipe(
        filter(entryId => !!entryId),
        takeUntilDestroyed())
      .subscribe(entryId => {
        this.router.navigate([ 'sectionentryform', { entryId } ])
    })
  }
  
  prepareData() {
    const entry = this.entry        
    const { displayableAttributes, questions, responses } = this.entryService.prepareReport(entry) // TODO: switch to entry.prepareReport and delete service prepareReport?
    const entrySpec = this.contentService.getEntrySpec(entry.indexType() as string)
    return {
      entry,
      parentIsCollection: this.parent?.isCollectionType(),
      displayableAttributes,
      questions,
      responses,
      entryTypeName: this.getTypeName(entry),
      backgroundColor: entrySpec.backgroundColor,
      longStyle: entrySpec.hasBehavior(BEHAVIOR_USE_LONG_REPORT_CARD_STYLE)
    }
  }

  async confirmRemove(entry: Entry) {
    const header = this.parent?.isCollectionType() ?
                `Are you sure you want to remove this entry from the Collection?` :
                `Are you sure you want to delete this entry?`
    await this.utilityService.confirm({
      header,
      confirm: async () => {
        if (this.parent?.isCollectionType())
          await this.entryService.removeEntryFromCollection(this.parent, entry)
        else
          await this.entryService.deleteEntryLite(entry)        
      }
    })
  }
  
  getTypeName(entry: Entry) {
    // If entry is an Action
    if (entry.parentEntryId)
      return this.contentService.getAction(entry.subtype).description
    else
      return this.contentService.getSingularGlobal(entry.indexType() as string)
  }
}

