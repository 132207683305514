import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, fromEvent, merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {
  private onlineStatusSubject: BehaviorSubject<boolean>;
  public onlineStatus$: Observable<boolean>;
  private onlineEvent: Observable<Event>;
  private offlineEvent: Observable<Event>;

  constructor() {
    this.onlineStatusSubject = new BehaviorSubject(navigator.onLine);
    this.onlineStatus$ = this.onlineStatusSubject.asObservable();

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    merge(
        this.onlineEvent.pipe(map(() => true)),
        this.offlineEvent.pipe(map(() => false)),
        of(navigator.onLine))
    .pipe(takeUntilDestroyed())
    .subscribe((status) => {
        this.onlineStatusSubject.next(status);
    });
  }
}