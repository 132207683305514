<div class="chat-feed-item">
    <ion-item detail="true" lines="none" class="feed-item blink_me" (click)="click()">            
        <ion-icon name="person-circle-outline" size="large"></ion-icon>
        <ng-container *ngIf="state.nextChat; else completed">
            <div class="details-wrapper" style="width:100%; padding-bottom: 8px; margin-left: 10px;">        
                <h2 class="title">
                    <cheaseed-global 
                        key="onboardingfeed.completesetup.title"
                        default="Complete Your Setup"></cheaseed-global>
                </h2>
                <p class="subtitle">            
                    {{ state.nextChat.subtitle }}
                </p>
                <div style="padding-top: 6px; font-size: 12px; color: gray;">
                    {{ state.currentStep }} of {{ state.totalSteps }} completed
                </div>
                <ion-progress-bar 
                    *ngIf="state?.totalSteps"
                    class="progress-bar"
                    [value]="(state.currentStep || 0) / (state.totalSteps || 1)">
                </ion-progress-bar>
            </div>
        </ng-container> 
        <ng-template #completed>
            <div class="details-wrapper" style="width:100%; padding-top: 8px; padding-bottom: 8px;">        
                <h2 class="title">
                    <cheaseed-global 
                        key="onboardingfeed.setupcompleted.title"
                        default="Congratulations!"></cheaseed-global>
                </h2>
                <p class="subtitle" style="padding-top: 4px;">            
                    <cheaseed-global 
                        key="onboardingfeed.setupcompleted.subtitle"
                        default="You've completed onboarding"></cheaseed-global>                    
                </p>
                <div style="padding-top: 8px; font-weight: bold;">Completed {{ state.totalSteps }} of {{ state.totalSteps }}</div>
                <ion-progress-bar 
                    class="progress-bar"
                    [value]="1.0">
                </ion-progress-bar>
            </div>  
        </ng-template>
    </ion-item>
</div>