<div>

    <div style="padding: 0; text-align: center;">
        <ion-button color="medium" style="font-weight: bold" (click)="clickAdd()">
        Add Entry
        </ion-button>
    </div>

    <div *ngFor="let entryType of selectedEntryTypes" class="type-div">
        <div class="type-header">{{ entryType.key }}</div>
        <ion-list class="item-list">
            <div *ngFor="let entry of entryType.entries">
                <ion-item
                    lines="none"
                    class="collection-item"
                    detail="false">
                    <ion-label class="ion-text-wrap">
                        {{ entry.displayName }}
                    </ion-label>
                    <ion-button slot="end" fill="clear" (click)="removeEntry(entry)">
                        <ion-icon name="remove-circle-outline" slot="end"></ion-icon>
                    </ion-button>
                </ion-item>
            </div>
        </ion-list>
    </div>

</div>

<ion-modal 
  [isOpen]="isModalOpen$ | async" 
  [canDismiss]="true"
  (didDismiss)="dismissModal()">
  <ng-template>
    <ion-content>
        <ng-container *ngIf="modalData$ | async as data">
            <cheaseed-entry-selector-modal
                [notNowOption]="false"
                [remindMeOption]="false"
                [title]="'Select ' + data.entryTitle"
                [entries]="data.availableEntries"
                (selectionChanged)="modalSelectionChanged($event)">
            </cheaseed-entry-selector-modal>
        </ng-container>
    </ion-content>
  </ng-template>
</ion-modal>