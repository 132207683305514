import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PointsService, SeedService } from '@cheaseed/cheaseed-core';
import { ProductPricingOffer } from '@cheaseed/node-utils';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cheaseed-acquire-consumable',
  templateUrl: './acquire-consumable.component.html',
  styleUrls: ['./acquire-consumable.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcquireConsumableComponent implements OnInit {

  @Input() offers: ProductPricingOffer[] = []
  @Input() title = ''
  @Input() route?: ActivatedRouteSnapshot

  purchasing$ = new BehaviorSubject(false)

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    public pointsService: PointsService,
    public seedService: SeedService
  ) { }

  ngOnInit(): void { }

  async purchase(offer: ProductPricingOffer) {
    this.purchasing$.next(true)
    const success = await this.seedService.purchaseSeeds(offer, this.route as ActivatedRouteSnapshot)
    this.purchasing$.next(false)
    this.modalController.dismiss()
  }

  async redeem(numPoints: number) {
    await this.seedService.redeemPoints(numPoints, this.route as ActivatedRouteSnapshot)
    this.modalController.dismiss()
  }

  dismiss() {
    this.modalController.dismiss()
  }
  isStandAlone() {
    return !this.route
  }
}