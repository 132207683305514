import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import {
  AbstractPage,
  ChatQueueService,
  ContentService,
  SharedChatService,
} from '@cheaseed/cheaseed-core';
import { Conversation, UNPINNABLE } from '@cheaseed/node-utils';

@Component({
  selector: 'app-chat-library-card',
  templateUrl: './chat-library-card.component.html',
  styleUrls: ['./chat-library-card.component.scss'],
})
export class ChatLibraryCardComponent extends AbstractPage implements OnInit {

  @Input() item: Conversation
  @Input() playlist: Map<string, any> = new Map()
  @Input() started: Map<string, any> = new Map()
  @Input() pinned: Map<string, any> = new Map()
  @Input() statuses: Map<string, any> = new Map()
  @Output() itemClicked = new EventEmitter<any>()
  isShareable = false

  constructor(
    private conversationService: SharedChatService,
    private chatQueueService: ChatQueueService,
    protected contentService: ContentService
    ) { 
      super(contentService) 
    }

  ngOnInit() {
    // console.log("ChatLibraryCardComponent ngOnInit", this.item)
    this.isShareable = this.conversationService.isChatShareable(this.item)
  }

  clickItem(item: Conversation) {
    this.itemClicked.emit( { item } )
  }

  removeFromPinned(item: any) {
    this.chatQueueService.unpinConversation(item)
  }

  togglePin(item: Conversation) {
    console.log("toggle pin", item)
    this.chatQueueService.togglePinConversation(item)
  }

  pinChat(item: any) {
    this.chatQueueService.pinConversation(item)
  }

  async shareChat(conversation: Conversation) {
    return await this.conversationService.shareChat(conversation)
  }

  canPin(item: Conversation) {
    return !item.hasBehavior(UNPINNABLE) && !this.pinned.has(item.name)
  }

  canUnpin(item: Conversation) {
    return !item.hasBehavior(UNPINNABLE) && this.pinned.has(item.name)
  }

}
