import { Component, Input } from '@angular/core';
import { ContentService, SharedEventService, SharedUserService, MediaService } from '@cheaseed/cheaseed-core';

@Component({
  selector: 'cheaseed-invite-friend',
  templateUrl: './invite-friend.component.html',
  styleUrls: ['./invite-friend.component.scss']
})
export class InviteFriendComponent {

  @Input() deeplink = ''
  @Input() shareMessage = ''

  constructor(
    private contentService: ContentService,
    private eventService: SharedEventService,
    private userService: SharedUserService,
    private mediaService: MediaService,
  ) { }

  async clickInvite() {
    const result = await this.generateBranchLink()
    if (result?.completed)
      this.eventService.recordShareContent({ id: 'invite-friend', app: result.app })
  }

  async generateBranchLink() {

    // optional fields
    const analytics = {
      channel: 'app',
      feature: 'invite',
      campaign: 'invite',
    }

    // optional fields
    const properties:any = {
      $desktop_url: 'https://www.cheaseed.com',
      friendInviter: this.userService.getCurrentUserId()
    }
    
    // Add custom deeplink if provided
    if (this.deeplink)
      properties['$deeplink_path'] = this.deeplink

    const shareText = this.shareMessage || this.getGlobal("inviteshare.message") || 'Join this chea seed challenge!'

    // share sheet
    return await this.mediaService.shareBranchDeepLink(analytics, properties, shareText)
  }
  
  getGlobal(key:string) {
    return this.contentService.getGlobal(key)
  }
}
