import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { SharedChatService, SharedEventService, UtilityService, CleverTapService, ContentService } from '@cheaseed/cheaseed-core'
import { Platform } from '@ionic/angular'
import { Preferences } from '@capacitor/preferences'
//import { AndroidPostNotificationsPermission, PermissionStatus } from 'capacitor-plugin-android-post-notifications-permission'
import {
  PushNotifications,
  PushNotificationSchema,
  Token,
  ActionPerformed,
  RegistrationError
} from '@capacitor/push-notifications'

import { Device } from '@capacitor/device'

const DEVICE_TOKEN_KEY = 'push-notifications-device-token'
const ANDROID_13 = 13

export interface PushNotificationPayload {
  title: string,
  message: string,
  type: string,
  id: string,
  challengeDocId: string,
  challengeOwnerId: string
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(
    private platform: Platform,
    private router: Router,
    private ngZone: NgZone,
    private utilityService: UtilityService,
    private conversationService: SharedChatService,
    private eventService: SharedEventService,
    private contentService: ContentService,
    private clevertapService: CleverTapService) { }

  async getDeviceToken() {
    const ret = await Preferences.get({ key: DEVICE_TOKEN_KEY })
    const value = JSON.parse(ret.value)
    console.log("getDeviceToken", value)
    return value
  }

  resetBadgeCount() {
    PushNotifications.removeAllDeliveredNotifications()
  }

  async registerPushNotifications() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting

    //console.log("Registering Push Notification listeners...")
    // Clean up if previously listened to
    PushNotifications.removeAllListeners()
    this.addAllListeners()
    // Check if device token is present
    const token = await this.getDeviceToken()
    console.log(`os version = ${(await Device.getInfo()).osVersion}`)

    if (!token) {
      // extra check for android. For android 12 and earlier permission is always granted
      if (this.platform.is('ios') ||
        (this.platform.is('android') && parseInt((await Device.getInfo()).osVersion) >= ANDROID_13)
      ) {
        await this.utilityService.notify({
          header: this.contentService.getGlobal("allow.notifications.title"),
          message: this.contentService.getGlobal("allow.notifications.message"),
          okText: 'OK 🎉',
          cancel: async () => { await this.requestPermission() }
        })
      }
      else {
        console.log(`Android version is ${(await Device.getInfo()).osVersion} - notifications are on by default`)
        await this.registerPushNotificationAndCreateChannel()
      }

    }
  }

  async requestPermission() {
    //let result, receive = false
    //if (this.platform.is('ios')) {
      const result = await PushNotifications.requestPermissions()
      //receive = result.receive
    //}
    /*else {
      //Temporary workaround till https://github.com/ionic-team/capacitor-plugins/issues/1135 is fixed
      // Remove this code once fix is released
      if (this.platform.is('android')) {
        result = await AndroidPostNotificationsPermission.checkPermissions()
        let permissionStatus: PermissionStatus
        if (result.postNotifications !== 'granted') {
          permissionStatus = await AndroidPostNotificationsPermission.requestPermissions({
            permissions: ['postNotifications']
          });
          console.log('Android notification permission request', JSON.stringify(permissionStatus))
        }
        receive = permissionStatus.postNotifications === 'granted'

      }
    }*/
    if (result.receive) {
      // Register with Apple / Google to receive push via APNS/FCM
      console.log("Push Notification permission granted")
      await this.registerPushNotificationAndCreateChannel()
    } else {
      // Show some error
      console.warn("Push Notification permission was not granted")
    }
  }

  async registerPushNotificationAndCreateChannel() {
    await PushNotifications.register()
    this.clevertapService.registerPush()
    this.clevertapService.createNotificationChannel()
  }

  addAllListeners() {

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      async (token: Token) => {
        // alert('Push registration success, token: ' + token.value);
        console.log('Push registration success, token: ' + JSON.stringify(token))
        await Preferences.set({
          key: DEVICE_TOKEN_KEY,
          value: JSON.stringify(token.value)
        })
      }
    )

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: RegistrationError) => {
        // alert('Error on registration: ' + JSON.stringify(error));
        console.log('Error on registration: ' + JSON.stringify(error))
      }
    )

    if (this.platform.is('ios')) {
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        async (n: PushNotificationSchema) => {
          /*
            {
              "body": "not3text",
              "badge": 7,
              "subtitle": "",
              "id": "3FCFE2AD-1236-4632-A9D5-64234D5CFE24",
              "data": {
                "google.c.a.c_id": "6137685987221126146",
                "google.c.a.e": "1",
                "google.c.a.udt": "0",
                "google.c.a.c_l": "notification3",
                "aps": {
                  "badge": 7,
                  "alert": { "title": "not3title", "body": "not3text" },
                  "sound": "default"
                },
                "gcm.n.e": "1",
                "gcm.notification.sound2": "default",
                "customkey2": "value2",
                "gcm.message_id": "1594268995276196",
                "customkey1": "value1",
                "google.c.sender.id": "1068743795207",
                "google.c.a.ts": "1594268995"
              },
              "title": "not3title"
            }
          */
          console.log('Push received: ' + JSON.stringify(n));
          const notificationId = n.id,
            { title, subtitle, body } = n,
            { type, id } = n.data
          console.log("Notification", notificationId, title, subtitle, body)
          // console.log(notification.data.customkey1)
          // console.log(notification.data.customkey2) 
          //this.eventService.recordPushNotificationReceived({ title, body, type, id })
          if (type === 'chat')
            await this.conversationService.checkPushAsNewsflash(id)
        }
      )

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (action: ActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(action))
          const notificationId = action.notification.id
          const { title, subtitle, body } = action.notification,
            { type, id, challengeDocId, challengeOwnerId } = action.notification.data
          const payload: PushNotificationPayload = { title, message: body, type, id, challengeDocId, challengeOwnerId }
          console.log("Notification", action.actionId, notificationId, payload)
          this.ngZone.run(this.actionAfterNotificationTapped, this, [payload, false])
        }
      )
    }
    else if (this.platform.is('android')) {
      document.addEventListener('onPushNotification', (e: any) => {
        const notification = e.notification
        const { wzrk_id, wzrk_st, nm, nt, id, type, challengeDocId, challengeOwnerId } = notification
        const payload: PushNotificationPayload = { title: nt, message: nm, type, id, challengeDocId, challengeOwnerId }
        console.log("Notification", wzrk_id, nt, wzrk_st, payload)
        this.ngZone.run(this.actionAfterNotificationTapped, this, [payload, true])
      });
    }
  }

  async actionAfterNotificationTapped(payload: PushNotificationPayload, shouldCreateNewsFlash = false) {
    this.eventService.recordPushNotificationActionPerformed(payload)
    console.log('actionAfterNotificationTapped: payload', JSON.stringify(payload))
    if (payload.type === 'chat') {
      if (shouldCreateNewsFlash) {
        await this.conversationService.checkPushAsNewsflash(payload.id)
      }
      // TODO: check if current url is already this conversation; if so, don't navigate
      const routeUrl = this.router.url
      console.log("navigating from Router.url", routeUrl)
      // If we are currently on conversation page
      if (routeUrl.includes('conversation')) {
        // If we are on the same conversation page, don't navigate
        // If we are on a different conversation page, set the next conversation
        const currChat = this.conversationService.nextConversation$.getValue()
        if (currChat !== payload.id) {
          this.conversationService.setNextConversation(payload.id)
        }
      }
      else {
        await this.router.navigate(['conversation', payload.id])
      }
    }
    else if (payload.type === 'challenge-invite') {
      await this.router.navigate(['challenge-view', payload.challengeDocId,
        { invite: true, challengeOwnerId: payload.challengeOwnerId }])
    }
    else if (payload.type === 'entry') {
      if (payload.challengeDocId)
        await this.router.navigate(['challenge-view', payload.challengeDocId,
          { notification: true }])
      else {
        console.log('Navigating to sectionentryform with id', payload.id)
        await this.router.navigate(['sectionentryform', { entrySpecId: payload.id }])
      }
    }
    else if (payload.type === 'route')
      await this.router.navigateByUrl(payload.id)
  }

}
