import { NgModule } from "@angular/core"
import { PreloadAllModules, RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@cheaseed/cheaseed-core"
import { EntriesPayGuard } from "./entries_pay.guard"
import { ChatPayGuard } from './chat_pay.guard'
import { ChallengePayGuard } from "./challenge_pay.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs/home',
    pathMatch: 'full'
  },
  { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)},
  { path: 'login/:test', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'conversation/:conversationId', loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationPageModule), canActivate: [AuthGuard, ChatPayGuard] },
  { path: 'statement/:statementId', loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationPageModule), canActivate: [AuthGuard] },
  // { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule), canActivate: [AuthGuard] },
  // Handle deep links from shared tiles
  { path: 'share', redirectTo: 'tabs/home', pathMatch: 'full' },
  { path: 'share/:id', redirectTo: 'tabs/home', pathMatch: 'full' },
  // { path: 'email/:requestGroupId', loadChildren: './email/email.module#EmailPageModule', canActivate: [AuthGuard] },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule), canActivate: [AuthGuard] },
  { path: 'sectionentryform', loadChildren: () => import('./sectionentryform/sectionentryform.module').then(m => m.SectionentryformPageModule), canActivate: [AuthGuard, EntriesPayGuard] },
  { path: 'sectionentryform/:entryId', loadChildren: () => import('./sectionentryform/sectionentryform.module').then(m => m.SectionentryformPageModule), canActivate: [AuthGuard, EntriesPayGuard] },
  { path: 'form/:entrySpecId', loadChildren: () => import('./sectionentryform/sectionentryform.module').then(m => m.SectionentryformPageModule), canActivate: [AuthGuard, EntriesPayGuard] },
  { path: 'messages', loadChildren: () => import('./messages/messages.module').then(m => m.MessagesPageModule), canActivate: [AuthGuard] },
  { path: 'inspiration', loadChildren: () => import('./inspiration/inspiration.module').then(m => m.InspirationPageModule), canActivate: [AuthGuard] },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'advanced',
    loadChildren: () => import('./advanced/advanced.module').then( m => m.AdvancedPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tileshare/:id',
    loadChildren: () => import('./tileshare/tileshare.module').then( m => m.TilesharePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'entryview',
    loadChildren: () => import('./entryview/entryview.module').then( m => m.EntryviewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'satisfaction-status',
    loadChildren: () => import('./satisfaction-status/satisfaction-status.module').then( m => m.SatisfactionStatusPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'end-conversation',
    loadChildren: () => import('./end-conversation/end-conversation.module').then( m => m.EndConversationPageModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'reflection', 
    loadChildren: () => import('./reflection/reflection.module').then( m => m.ReflectionPageModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'favorites', 
    loadChildren: () => import('./favorites/favorites.module').then( m => m.FavoritesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'scheduledTasks',
    loadChildren: () => import('./scheduled-tasks/scheduled-tasks.module').then( m => m.ScheduledTasksPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'challenge-form',
    loadChildren: () => import('./challenge-form/challenge-form.module').then( m => m.ChallengeFormComponentModule),
    canActivate: [AuthGuard, ChallengePayGuard]
  },
  {
    path: 'challenge-form/:docId',
    loadChildren: () => import('./challenge-form/challenge-form.module').then( m => m.ChallengeFormComponentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'challenge-view/:docId',
    loadChildren: () => import('./challenge-view/challenge-view.module').then( m => m.ChallengeViewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'challenge-join/:docId',
    loadChildren: () => import('./challenge-join/challenge-join.module').then( m => m.ChallengeJoinPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'challenge-public-join/:specName',
    loadChildren: () => import('./challenge-public-join/challenge-public-join.module').then( m => m.ChallengePublicJoinPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'share-entry-tile/:entryId',
    loadChildren: () => import('./share-entry-tile/share-entry-tile.module').then( m => m.ShareEntryTilePageModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'invite-friend', 
    redirectTo: 'tabs/community/invites', 
    pathMatch: 'full'
  },
  {
    path: 'challenge-view-participants/:docId',
    loadChildren: () => import('./challenge-view-participants/challenge-view-participants.module').then( m => m.ChallengeViewParticipantsPageModule)
  },
  {
    path: 'challenges',
    loadChildren: () => import('./challenges/challenges.module').then( m => m.ChallengesPageModule)
  },
  {
    path: 'chat-search',
    loadChildren: () => import('./chat-search/chat-search.module').then( m => m.ChatSearchPageModule)
  },
  {
    path: 'force-app-update',
    loadChildren: () => import('./force-app-update/force-app-update.module').then( m => m.ForceAppUpdatePageModule)
  },
  {
    path: 'redeem-offer/:code',
    loadChildren: () => import('./redeem-offer/redeem-offer.module').then( m => m.RedeemOfferPageModule)
  },
  {
    path: 'invite-to-group/:groupName',
    loadChildren: () => import('./invite-to-group/invite-to-group.module').then( m => m.InviteToGroupPageModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
  //    enableTracing: true,  // <-- debugging purposes only
      preloadingStrategy: PreloadAllModules 
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
