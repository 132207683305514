import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from '@cheaseed/cheaseed-core';

@Component({
  selector: 'cheaseed-labelled-spinner',
  templateUrl: './labelled-spinner.component.html',
  styleUrls: ['./labelled-spinner.component.scss']
})
export class LabelledSpinnerComponent implements OnInit {

  @Input() title:string|undefined = undefined
  @Input() subtitle:string|undefined = undefined

  constructor(
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    if (!this.subtitle) {
      this.subtitle = this.contentService.currentSpinnerMessage()
    }
  }

}
