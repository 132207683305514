/* eslint-disable @angular-eslint/no-input-rename */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { AlertController, ActionSheetController, ModalController } from '@ionic/angular'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { VgApiService } from '@videogular/ngx-videogular/core'
import { ContentService, EntryService, FirebaseService, SharedUserService, VideoService } from '@cheaseed/cheaseed-core'
import { CaptureVideoComponent } from '../capture-video/capture-video.component'
import { MatFormFieldAppearance } from '@angular/material/form-field'
import { BehaviorSubject, debounceTime, filter } from 'rxjs'

@Component({
  selector: 'cheaseed-entry-field',
  templateUrl: './entry-field.component.html',
  styleUrls: ['./entry-field.component.scss'],
})
export class EntryFieldComponent implements OnInit {
  
  @Input('attrib') attributeSpec: any
  @Input('form') entryFormGroup: UntypedFormGroup
  @Input() useShortDescription = false
  @Output() fieldChanged = new EventEmitter<boolean>()
  inputFieldSubject = new BehaviorSubject<any>(null)

  popoverOptions: any = { cssClass: "popover" }
  fieldAppearance:MatFormFieldAppearance = "outline"
  segmentIcons:any[] = []
  videoPlayerAPI: VgApiService|null = null
  videoUrl: SafeUrl
  dynamicOptions:any[] = []
  selectableEntries:any[] = []
  selectedEntries:any[] = []
  loading = false
  questionText = ""

  constructor(private alertController: AlertController,
              private actionSheetController: ActionSheetController,
              private modalController: ModalController,
              private userService: SharedUserService,
              private entryService: EntryService,
              public contentService: ContentService,
              private videoService: VideoService,
              private firebaseService: FirebaseService,
              public sanitizer: DomSanitizer) {

    this.inputFieldSubject.pipe(
      debounceTime(500),
      filter(value => !!value))
      .subscribe(value => {
        const { id, event } = value
        // console.log("inputFieldSubject", value, id, event)
        this.inputChanged(id, event)
      })
  }

  updateSegmentIcons(newvalue:number , markAsDirty:boolean = false) {
    const val = newvalue
    this.segmentIcons.fill(0)
    if (val) {
      this.segmentIcons[val - 1] = 1
      this.patchFormGroup(this.attributeSpec.attributeName, val, markAsDirty)
    }
  }

  patchFormGroup(key:string, val:any, markAsDirty:boolean = false) {  
    const patch:any = {}
    patch[key] = val
    this.entryFormGroup.patchValue(patch)
    // console.log("patchFormGroup", patch, this.entryFormGroup)
    if (markAsDirty)
      this.entryFormGroup.controls[key].markAsDirty()
  }

  ngOnInit() {
//     console.log('entry form group', this.entryFormGroup) 
    const spec = this.attributeSpec
//     console.log('attrSpec', spec)
    // Assign the right questionText
    this.questionText = 
      this.useShortDescription && spec.shortQuestion 
        ? spec.shortQuestion 
        : spec.question

    if (spec.inputType === "SCALE") {
      this.segmentIcons = Object.assign([], spec.scaleRange).fill(0)
      // console.log("segmentIcons", this.segmentIcons)
      this.updateSegmentIcons(this.entryFormGroup.value[spec.attributeName])
    }
    else if (spec.inputType === "MULTIOPTIONS") {
      const val = this.entryFormGroup.value[spec.attributeName]
      spec.optionLinks = spec.optionLinks.map(opt => {
        const newopt = Object.assign({}, opt)
        newopt.checked = val ? !!val[newopt.name] : null
        return newopt
      })
    }
    else if (spec.inputType === "VIDEO" || spec.inputType === "VIDEOCAPTURE") {
      const url = this.entryFormGroup.value[spec.attributeName]
      this.updateVideoSource(url)
    }
    else if (spec.inputType === "ENTRYSELECTOR") {
      this.loading = true
      const subentries = this.entryFormGroup.value[spec.attributeName]
      this.selectedEntries = [ ... subentries ]
      this.loading = false
    }
    else if (["Goal"].includes(spec.inputSubtype)) {
      const results = this.entryService.getEntriesOfType(spec.inputSubtype)
      this.dynamicOptions = results.map(g => { return { id: g.docId, name: g.displayName }})
    }
  }

  emitFieldChanged(source:string, values:any) {
    const emit:any = { source: source, values: values } 
    this.fieldChanged.emit( emit )
  }

  async comboboxChanged(control:string, ev: any) {
    const newValue = ev.value
    console.log('Combobox changed', control, newValue);
    if (newValue === "Other") {
      const alert = await this.alertController.create({
        header: 'Add New',
        inputs: [
          {
            name: 'otherValue',
            type: 'text',
            // placeholder: 'Type a new value for ' + control
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              this.patchFormGroup(control, null)
              console.log('Confirm Cancel');  
            }
          }, 
          {
            text: 'Ok',
            handler: async data => {
              if (data.otherValue) {
                this.patchFormGroup(control, data.otherValue)
                const choicelist = this.attributeSpec.choicelist
                if (!choicelist.includes(data.otherValue))
                  choicelist.push(data.otherValue)
                // Update UserKeys
                this.userService.appendUserKeyList(this.attributeSpec.attributeName, data.otherValue)
                if (this.attributeSpec.behaviors?.includes("defaultToLastUsedValue"))
                  this.userService.setUserKeyLastUsed(this.attributeSpec.attributeName, data.otherValue)
              }
              else {
                this.patchFormGroup(control, data.otherValue || ' ') // force blank if no value
              }
              this.emitFieldChanged(this.attributeSpec.attributeName, this.entryFormGroup.value)
              }
          }
        ]
      });
      await alert.present();
    }
    else {
      if (this.attributeSpec.behaviors?.includes("defaultToLastUsedValue"))
        this.userService.setUserKeyLastUsed(this.attributeSpec.attributeName, newValue )
      this.emitFieldChanged(this.attributeSpec.attributeName, this.entryFormGroup.value)
    }
  }

  updateChecked(obj:any, ev:any) {
    obj.checked = !obj.checked
    const val = []
    const key = this.attributeSpec.attributeName
    // console.log("updateChecked", key, obj, ev)
    for (const o of [...this.selectableEntries, ...this.selectedEntries])
      if (o.checked)
        val.push(o.docId)
    // console.log("updateChecked checked", val)
    this.patchFormGroup(key, val, true)
    // Emit output
    this.emitFieldChanged(this.attributeSpec.attributeName, this.entryFormGroup.value)
  }

  selectionChanged(id:string, ev:any) {
    // console.log("selectionChanged", id, ev)
    if (this.attributeSpec.inputType === "SCALE")
      this.updateSegmentIcons(ev.detail.value, true)
    else
      this.patchFormGroup(id, (ev.detail || ev.source || ev.target).value, true)
    // Emit event to container for evaluation of capture logic
    this.emitFieldChanged(id, this.entryFormGroup.value)    
  }

  inputChanged(id:string, ev:any) {
    // For textfield input, we must reset the selectionStart after patching (only necessary on iOS)
    // console.log("selectionChanged", id, ev)
    const pos = ev.target.selectionStart
    // console.log("idx", pos)
    this.selectionChanged(id, ev)
    ev.target.setSelectionRange(pos, pos)
  }

  onDateChangeEvent(id:string, e:any): void {
    // console.log(e.target.value)
    this.patchFormGroup(id, e.target.value, true)
    this.emitFieldChanged(id, this.entryFormGroup.value)
  }

  setChip(item:any) {
    this.patchFormGroup(this.attributeSpec.attributeName, item.name, true)
    this.emitFieldChanged(item.name, this.entryFormGroup.value)
  }

  isChipSelected(item:any) {
    const result = this.entryFormGroup.value[this.attributeSpec.attributeName] === item.name
    return result
  }

  saveAttribute(val:any) {
    this.patchFormGroup(this.attributeSpec.attributeName, val, true)
  }

  async selectMedia() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Video',
      cssClass: 'plus-action-sheet',
      buttons: [
        {
          text: 'Record',
          handler: () => {
            this.displayRecorder()
          }
        },
        // {
        //   text: 'Select Video',
        //   handler: () => {
        //     console.log("TODO: select video from library")
        //   }
        // },
        {
          text: 'Not now',
          icon: 'close',
          cssClass: 'cancel-button',
          handler: () => undefined
        }
      ]
    });
    await actionSheet.present();
  }

  async displayRecorder() {
    console.log("will display video capture")
    await this.openModalVideoRecorder()
  }

  async openModalVideoRecorder() {
    const modal = await this.modalController.create({
      showBackdrop: false,
      // cssClass: 'capture-video-modal',
      component: CaptureVideoComponent,
      componentProps: {}
    });
    modal.onDidDismiss().then(async (result) => {
      console.log("modal returned", JSON.stringify(result))
      if (result.data?.fetchableUrl) {
        const { fetchableUrl } = result.data
        await this.updateVideoSource(fetchableUrl)
        this.patchFormGroup(this.attributeSpec.attributeName, fetchableUrl, true)
        this.emitFieldChanged(this.attributeSpec.attributeName, this.entryFormGroup.value)
      }
    })
    await modal.present()
  }

  async updateVideoSource(url: string) {
    if (url) {
      console.log("sanitizing url", url)
      // If still a local capacitor file, convert to a working url
      //   iOS file appears as capacitor://
      //   Android file appears like: http://localhost/_capacitor_file_/storage/emulated/0/Android/data/com.cheaseed.android/files/VID_20210615004741.mp4
      if (url.includes('capacitor')) {
        this.videoUrl = await this.videoService.getSanitizedBlobUrl(url) as SafeUrl
        console.log("sanitized url", JSON.stringify(this.videoUrl))
      }
      else {
        // Otherwise, get the downloadable URL from Firebase
        this.firebaseService.getVideoUrl(url)
          .subscribe(url => { 
            this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(url)
            console.log("downloadUrl", this.videoUrl)
         })
      }
    }
  }

  onPlayerReady(api: VgApiService) {
    console.log("video play ready")
    // Subscribe to video ended event
    api.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
          // Set the video to the beginning
          api.getDefaultMedia().currentTime = 0;
      }
    );
  }

  checkboxClicked(opt:any, ev:any) {
    console.log(opt, ev.detail.checked)
    opt.checked = ev.detail.checked
    const state = Object.fromEntries(
      this.attributeSpec.optionLinks
        .filter((opt:any) => opt.checked)
        .map((opt:any) => [opt.name, true]))
    this.patchFormGroup(this.attributeSpec.attributeName, state, true)
    this.emitFieldChanged(this.attributeSpec.attributeName, this.entryFormGroup.value)
  }

  selectorChanged(payload) {
    if (payload) {
      // Emit output
      const key = this.attributeSpec.attributeName
      console.log("selectorChanged", key, payload)
      this.patchFormGroup(key, payload.selected, true)
      this.emitFieldChanged(key, this.entryFormGroup.value)
    }
  }

}
