<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title style="font-size: 18px; padding: 0 50px 0 50px;">Challenges</ion-title>
  </ion-toolbar> 
</ion-header>
  
<ion-content>
  <ng-container *ngIf="challengeType === 'private'">
    <ion-list-header>Create a Challenge</ion-list-header>
    <ion-list lines="full">
        <ion-item 
            *ngFor="let c of availables"
            class="challenge-item" 
            detail="true"
            (click)="clickChallenge(c)">
            <ion-label class="ion-text-wrap">{{ c.title }}</ion-label>
        </ion-item>
    </ion-list>
  </ng-container>

  <ng-container *ngIf="challengeType === 'public'">
    <ion-list-header *ngIf="availables.length > 0">Join a Challenge</ion-list-header>
    <ion-list lines="full">
      <ion-item 
          *ngFor="let c of availables"
          class="challenge-item" 
          detail="true"          
          (click)="clickChallenge(c)">
          <ion-label class="ion-text-wrap">{{ c.title }}</ion-label>
      </ion-item>
    </ion-list>
  </ng-container>
</ion-content>
