import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DateFnsModule } from 'ngx-date-fns';

import { SeriesChatListComponent } from './series-chat-list/series-chat-list.component';
import { EntryCardComponent } from './entry-card/entry-card.component';
import { EntryReportCardComponent } from './entry-report-card/entry-report-card.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { StatementResourcesComponent } from './statement-resources/statement-resources.component';
import { LabelledSpinnerComponent } from './labelled-spinner/labelled-spinner.component';
import { ChatPlayerComponent } from './chat-player/chat-player.component';
import { SeriesIntroComponent } from './series-intro/series-intro.component';
import { ReflectPadComponent } from './reflect-pad/reflect-pad.component';
import { InviteFriendComponent } from './invite-friend/invite-friend.component';
import { NewsflashComponent } from './newsflash/newsflash.component';
import { MylistFeedComponent } from './mylist-feed/mylist-feed.component';
import { RecommendedChatFeedComponent } from './recommended-chat-feed/recommended-chat-feed.component';
import { ScrollingFilterChipsComponent } from './scrolling-filter-chips/scrolling-filter-chips.component';
import { StatsFeedComponent } from './stats-feed/stats-feed.component';
import { ChallengeFeedComponent } from './challenge-feed/challenge-feed.component';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { EntryFeedComponent } from './entry-feed/entry-feed.component';
import { OnboardingFeedComponent } from './onboarding-feed/onboarding-feed.component';
import { ChatFeedbackComponent } from './chat-feedback/chat-feedback.component';
import { EntryFieldComponent } from './entry-field/entry-field.component';
import { CaptureVideoComponent } from './capture-video/capture-video.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { EntryBlockComponent } from './entry-block/entry-block.component';
import { CollectionSelectorComponent } from './collection-selector/collection-selector.component';
import { HomeItemComponent } from './home-item/home-item.component';
import { ChallengeListComponent } from './challenge-list/challenge-list.component';
import { ChallengesBrowserComponent } from './challenges-browser/challenges-browser.component';
import { ChallengeSelectorComponent } from './challenge-selector/challenge-selector.component';
import { HomeLevelsComponent } from './home-levels/home-levels.component';
import { ResourcesBrowserComponent } from './resources-browser/resources-browser.component';
import { EntrySelectorComponent } from './entry-selector/entry-selector.component';
import { EntrySelectorModalComponent } from './entry-selector-modal/entry-selector-modal.component';
import { AcquireConsumableComponent } from './acquire-consumable/acquire-consumable.component';
import { NotificationButtonComponent } from './notification-button/notification-button.component';
import { PromptStatusButtonComponent } from './prompt-status-button/prompt-status-button.component';
import { IsoDateStrPipe } from '@cheaseed/shared/util';
import { GlobalMessageComponent } from './global-message/global-message.component';
import { InstructionCardComponent } from './instruction-card/instruction-card.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatSliderModule,
    MatChipsModule,
    ScrollingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    DateFnsModule,
    IsoDateStrPipe,
  ],
  declarations: [
    SeriesChatListComponent,
    EntryCardComponent,
    EntryReportCardComponent,
    BackButtonComponent,
    StatementResourcesComponent,
    LabelledSpinnerComponent,
    ChatPlayerComponent,
    SeriesIntroComponent,
    ReflectPadComponent,
    InviteFriendComponent,
    NewsflashComponent,
    MylistFeedComponent,
    RecommendedChatFeedComponent,
    ScrollingFilterChipsComponent,
    StatsFeedComponent,
    ChallengeFeedComponent,
    CounterInputComponent,
    EntryFeedComponent,
    OnboardingFeedComponent,
    ChatFeedbackComponent,
    EntryFieldComponent,
    CaptureVideoComponent,
    EntryBlockComponent,
    CollectionSelectorComponent,
    HomeItemComponent,
    ChallengeListComponent,
    ChallengesBrowserComponent,
    ChallengeSelectorComponent,
    HomeLevelsComponent,
    ResourcesBrowserComponent,
    EntrySelectorComponent,
    EntrySelectorModalComponent,
    AcquireConsumableComponent,
    NotificationButtonComponent,
    PromptStatusButtonComponent,
    GlobalMessageComponent,
    InstructionCardComponent
  ],
  exports: [
    SeriesChatListComponent,
    EntryCardComponent,
    EntryReportCardComponent,
    BackButtonComponent,
    StatementResourcesComponent,
    LabelledSpinnerComponent,
    ChatPlayerComponent,
    SeriesIntroComponent,
    ReflectPadComponent,
    InviteFriendComponent,
    NewsflashComponent,
    MylistFeedComponent,
    RecommendedChatFeedComponent,
    ScrollingFilterChipsComponent,
    StatsFeedComponent,
    ChallengeFeedComponent,
    EntryFeedComponent,
    OnboardingFeedComponent,
    ChatFeedbackComponent,
    EntryFieldComponent,
    CaptureVideoComponent,
    EntryBlockComponent,
    CollectionSelectorComponent,
    HomeItemComponent,
    ChallengeListComponent,
    ChallengesBrowserComponent,
    ChallengeSelectorComponent,
    HomeLevelsComponent,
    ResourcesBrowserComponent,
    EntrySelectorComponent,
    EntrySelectorModalComponent,
    ScrollingModule,
    AcquireConsumableComponent,
    NotificationButtonComponent,
    PromptStatusButtonComponent,
    GlobalMessageComponent,
    InstructionCardComponent
  ],
})
export class UiCoreModule {}
