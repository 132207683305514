<ion-header color="primary">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="close()">
          <ion-icon color="light" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
        Capture Video
      </ion-title>
    </ion-toolbar>
  </ion-header>
  
  <ion-content style="--background: transparent">
    <div #vidwindow style="opacity: 0.0"></div>
  </ion-content>
  
  <!-- These need to be outside of the opacity 0 parent -->
  <ion-footer class="footer-button" style="text-align: center;">
    
    <ion-button size="large" color="{{ isRecording ? 'danger' : 'primary' }}" (click)="toggleRecording()">
      <ion-label style="padding-right: 10px">{{ timerString || '00:00' }}</ion-label> 
      <ion-icon slot="end" size="large" name="{{ isRecording ? 'stop-circle' : 'videocam' }}"></ion-icon> 
    </ion-button>
    <ion-button *ngIf="doneRecording" color="primary" (click)="saveRecording()">
      Use
    </ion-button>
  <!-- <ion-button *ngIf="doneRecording" size="large" (click)="playRecording()">
      <ion-icon size="large" name="play"></ion-icon>  
    </ion-button> -->
  </ion-footer>