import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SharedUserService, SeedService, ContentService, EntryService } from '@cheaseed/cheaseed-core';
import { AcquireConsumableComponent } from '@cheaseed/ui-core';
import { filter, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntriesPayGuard implements CanActivate {

  constructor(
    private userService: SharedUserService,
    private entryService: EntryService,
    private seedService: SeedService,
    private contentService: ContentService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.user$
      .pipe(
        filter(user => !!user),
        map(user => {
          if(!this.contentService.isSeedsEnabled())
            return true
          // console.log("EntriesPayGuard route.params", route.params)
          if ( route.params.entrySpecId) {
            const cost = this.entryService.getEntrySeedCost(route.params.entrySpecId)
            if (user?.seedBalance as number >= cost  ) {
              return true
            }
            else {
              this.seedService.promptPurchase$.next(route)
              return false
            }
          }
          else {
            // console.log("EntriesPayGuard: no entrySpecId in route params")
            return true
          }
        }))
  }

}
