import { Component, inject } from '@angular/core';
import { HomeItemService, SharedEventService, ContentService, UtilityService, OnboardingService, HomeItem, ItemActionType, ItemState, EntryService } from '@cheaseed/cheaseed-core';
import { ADVICE_CHATS_KEY, splitTrim } from '@cheaseed/node-utils';
import { BehaviorSubject, debounceTime, filter, map, of, tap } from 'rxjs';

@Component({
  selector: 'cheaseed-home-levels',
  templateUrl: './home-levels.component.html',
  styleUrls: ['./home-levels.component.scss']
})
export class HomeLevelsComponent {

  contentService = inject(ContentService)
  homeItemService = inject(HomeItemService)

  tab$ = new BehaviorSubject('coaching')
  levelClicked$ = new BehaviorSubject<number>(0)
  levelNames:any[] = []
  
  isFirstTimeInLevels$ = this.homeItemService.isFirstTimeInLevels$
    .pipe(debounceTime(1000))

  filterState$ = new BehaviorSubject('active')

  todoFilterChipData$ = this.filterState$
    .pipe(
      map(type => ({
        selectedChip: type,
        filterChips: of([ 
          { type: 'active' },
          { type: 'done' },
          { type: 'skipped' }
        ])
      }))
    )

    coachingChats$ = this.contentService.loader$
      .pipe(
        filter(loader => !!loader),
        map(() => {
          const adviceChats = this.contentService.getGlobal(ADVICE_CHATS_KEY)
          return splitTrim(adviceChats)
            .map(chatname => this.contentService.getConversationNamed(chatname))
            .filter(c => !!c)
          }),
        map(chats => chats.map(c => {
              return {
                  docId: c?.name,
                  actionType: ItemActionType.CHAT,
                  actionId: c?.name,
                  title: c?.title,
                  message: c?.subtitle,
                  completed: false,
                  // required for HomeItem conformance
                  name: c?.name,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  state: ItemState.TODO,
                  required: false,
                  // icon: 'report'
                } as HomeItem
              })),
          // tap(chats => console.log('coachingChats', chats))
      )

  constructor(    
    public eventService: SharedEventService,
    private entryService: EntryService,
    
    public onboardingService: OnboardingService,
    public utilityService: UtilityService
  ) { }

  setLevelNames() {
    const levs = this.homeItemService.getLevelNames()
      .map((lev:any) => ({
        key: lev.key.split('.')[2],
        value: lev.value
      }))
    this.levelNames = levs.sort((a:any, b:any) => a.key - b.key)
  }

  segmentChanged(event: any) {
    const tab = event.detail.value
    this.tab$.next(tab)
    // this.filterState$.next('active')
  }

  levelClicked(level: number) {  
    console.log('levelClicked', level)
    if (this.levelNames.length === 0)
      this.setLevelNames()
    this.levelClicked$.next(level)
  }

  levelDismissed() {
    this.levelClicked$.next(0)
  }

  async clickCoachingItem(item: HomeItem) {
    const entry = this.entryService.getEntryById(item.entryId as string)
    if (entry && !entry.isUnapproved())
      this.homeItemService.click(item)
    else {
      await this.utilityService.notify({
        message: this.contentService.getGlobal('unapproved.alert.message')
      })
    }
  }

}
