import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cheaseed-series-chat-list',
  templateUrl: './series-chat-list.component.html',
  styleUrls: ['./series-chat-list.component.scss']
})
export class SeriesChatListComponent implements OnInit {

  @Input() chats: any[] = []
  @Input() current: any = null
  @Input() showCheckmarks = false
  @Input() highlightCurrent = true
  
  constructor() { }

  ngOnInit(): void {
  }

}
