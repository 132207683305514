<ng-container *ngIf="this.playlist$ | async as chat">
    <div class="chat-feed-item">
        <ion-item detail="true" lines="none" class="feed-item" (click)="click(chat)">
            <mat-icon class="icon">fact_check</mat-icon>
            <div class="details-wrapper">
                <h2 class="title">In My List</h2>
                <p class="subtitle">{{ chat.inprogress ? 'Complete' : 'Start'}} <i>{{ chat.title }}</i></p>
            </div>
        </ion-item>
    </div>  
</ng-container>
