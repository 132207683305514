import { Component, OnInit } from '@angular/core';
import { ContentService, SharedUserService } from '@cheaseed/cheaseed-core';

interface Shortcut {
  label: string;
  icon_name: string;
  color_expr: () => boolean;
  route: string;
  mat_icon_name?: string;
}
@Component({
  selector: 'cheaseed-reflect-pad',
  templateUrl: './reflect-pad.component.html',
  styleUrls: ['./reflect-pad.component.scss']
})
export class ReflectPadComponent implements OnInit {

  initialMessage = "Reflect to achieve your goals"

  shortcuts:Shortcut[] = [
    {
      label: 'Plan',
      icon_name: 'disc-outline',
      color_expr: () => this.userService.getUserKey("user.planChanged"),
      route: "/reflection"
    },
    {
      label: 'Favorites',
      icon_name: 'heart-outline',
      color_expr: () => this.userService.getUserKey("user.favoritesChanged"),
      route: "/favorites"
    },
    // {
    //   label: 'Challenges',
    //   icon_name: 'list-outline',
    //   color_expr: () => false,
    //   route: "/challenges"
    // }
    // {
    //   label: 'Takeaways',
    //   icon_name: 'bulb-outline',
    //   color_expr: () => this.userService.getUserKey("user.takeawaysChanged"),
    //   route: "/takeaways"
    // },
    // {
    //   label: 'Shoutouts',
    //   icon_name: 'megaphone-outline',
    //   color_expr: () => this.userService.getUserKey("user.shoutoutsChanged"),
    //   route: [ "/tabs/career/entries/Accolade", { launchFrom: 'home' } ]
    // },
    // {
    //   label: 'Collections',
    //   mat_icon_name: 'playlist_add',
    //   color_expr: () => this.userService.getUserKey("user.collectionsChanged"),
    //   route: [ "/tabs/career/entries/Collection", { launchFrom: 'home' } ]
    // }
  ];

  constructor(
    private userService: SharedUserService,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
  }

}
