import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VgCoreModule } from '@videogular/ngx-videogular/core'
import { VgControlsModule } from '@videogular/ngx-videogular/controls'
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play'
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering'

import { EntryformfieldComponent } from './entryformfield/entryformfield.component';
import { BlockentryformComponent } from './blockentryform/blockentryform.component';
import { ChatCardComponent } from './chat-card/chat-card.component';
import { ChatLibraryCardComponent } from './chat-library-card/chat-library-card.component';
import { UiCoreModule } from '@cheaseed/ui-core';

const components = [ 
  EntryformfieldComponent,
  BlockentryformComponent,
  ChatCardComponent,
  ChatLibraryCardComponent,
]
@NgModule({
  declarations: [ ...components ],
  imports: [
    CommonModule, 
    FormsModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatRippleModule,
    MatChipsModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSliderModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    UiCoreModule
  ],
  exports: [ ...components,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatInputModule,
    MatSliderModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatProgressSpinnerModule,
  ]
})
export class ComponentsModule { }
