<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Get Seeds</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()">
                <ion-icon style="color: white" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ng-container *ngIf="pointsService.userPoints$ | async as points">
        <div class="acquire-consumable">
            <div class="intro">
                <div class="title">
                    <ng-container *ngIf="route; else standalonemsg">
                        <cheaseed-global key="buymodal.message"></cheaseed-global>
                    </ng-container>
                    <ng-template #standalonemsg>
                        <cheaseed-global key="buymodal.standalone.message"></cheaseed-global>
                    </ng-template>
                </div>
                <div class="message" style="text-align: center">                
                    You currently have <b>{{ points.totalPoints }}</b> points and <b>{{ seedService.currentSeedCount$ | async }}</b> seeds.
                </div>
                <div class="message">
                    <ng-container *ngIf="points.totalPoints >= 100; else howtoredeem">                        
                        <cheaseed-global key="buymodal.redeem.message"></cheaseed-global>
                    </ng-container>
                    <ng-template #howtoredeem>                        
                        <cheaseed-global key="buymodal.earntoredeem.message"></cheaseed-global>
                    </ng-template>
                </div>
                <!-- <div class="message">                
                    <cheaseed-global key="buymodal.discount.message"></cheaseed-global>
                </div> -->
            </div>
            <ng-container *ngIf="purchasing$ | async">
                <mat-spinner class="chea-mat-spinner" style="margin-top: 0"></mat-spinner>
            </ng-container>
            <div class="offer-grid">
                <div class="offer" *ngFor="let offer of offers">
                    <div class="title">{{ offer.title }}<br>{{ offer.discount ? offer.discount + ' off' : '' }}</div>
                    <!-- <div class="price">{{ offer.price }}</div> -->
                    <button mat-raised-button (click)="purchase(offer)">Buy for {{ offer.price }}</button>
                </div>
            </div>
            <ng-container *ngIf="points.totalPoints >= 100">
                <div class="points">
                    <button mat-raised-button (click)="redeem(100)">Redeem 100 points for 100 seeds</button>
                </div>    
            </ng-container>
            <ng-container *ngIf="points.totalPoints >= 500">
                <div class="points">
                    <button mat-raised-button (click)="redeem(500)">Redeem 500 points for 500 seeds</button>
                </div>    
            </ng-container>
        </div>
    </ng-container>
</ion-content>