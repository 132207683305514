import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cheaseed-chat-feedback',
  templateUrl: './chat-feedback.component.html',
  styleUrls: ['./chat-feedback.component.scss'],
})
export class ChatFeedbackComponent implements OnInit {

  @Output() feedbackChanged = new EventEmitter<boolean>()
  states:any = {
    'confident': false,
    'motivated': false,
    'smarter': false,
  }

  constructor() {}

  ngOnInit() {}

  toggleState(key:string) {
    this.states[key] = !this.states[key]
    this.feedbackChanged.emit(this.states)
  }

  toggleConfident() {
    this.toggleState('confident')
  }
  toggleMotivated() {
    this.toggleState('motivated')
  }
  toggleSmarter() {
    this.toggleState('smarter')
  }

}
