import { Component, Input, OnInit } from '@angular/core';
import { ChatQueueService, ContentService } from "@cheaseed/cheaseed-core";

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent implements OnInit {

  @Input() chat:any
  @Input() showSeries: boolean
  series: any
  topic: any
  completionPercentage: number;

  constructor(
    public contentService: ContentService,
    public chatQueueService: ChatQueueService
  ) { }

  ngOnInit() {
    // console.log("ChatCardComponent", this.chat)
    const seriesName = this.chat.series?.values().next()?.value
    this.series = this.contentService.pathMap.get(seriesName)
    this.topic = this.contentService.topicsMap.get(this.series?._topic?.name)
  }
}
