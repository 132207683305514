import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService, EntryService } from '@cheaseed/cheaseed-core';

@Component({
  selector: 'cheaseed-entry-feed',
  templateUrl: './entry-feed.component.html',
  styleUrls: ['./entry-feed.component.scss']
})
export class EntryFeedComponent implements OnInit {
  @Input() state: any = null
  title: string|null = null;
  subtitle: any;

  constructor(
    private router: Router,
    private entryService: EntryService,
    public contentService: ContentService
  ) { }

  ngOnInit() {}

  getTitleMessage(state: any) {
    return this.contentService.getGlobal("entryShareFeed.title")
      .replace("$type", this.contentService.getSingularGlobal(state.subtype || state.type))
  }

  clickFeedItem(entry: any) {
    this.router.navigate( ['share-entry-tile', entry.docId ] )
    this.entryService.setEntryFeedState(null)
  }
}
