import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Entry } from '@cheaseed/node-utils';

@Component({
  selector: 'cheaseed-entry-selector-modal',
  templateUrl: './entry-selector-modal.component.html',
  styleUrls: ['./entry-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntrySelectorModalComponent {

  @Input() notNowOption = true
  @Input() remindMeOption = true
  @Input() numberToSelect = 1
  @Input() title = ''
  @Input() entries: Entry[] = []  
  @Output() selectionChanged = new EventEmitter()
  
  constructor() { }

  dismiss() {
    this.selectionChanged.emit({ selected: [] })
  }

  selectEntry(entry: Entry) {
    setTimeout(() => { this.selectionChanged.emit({ selected: [entry] }) }, 200)
  }

  clickNotNow() {
    this.selectionChanged.emit({ selected: [], notNow: true })
  } 

  clickRemindMe() {
    this.selectionChanged.emit({ selected: [], remindMe: true })
  } 

}
