<ng-container *ngIf="this.recommendedChats$ | async as chat">
    <div class="chat-feed-item">
        <ion-item detail="true" lines="none" class="feed-item" (click)="click(chat)">
            <mat-icon class="icon">campaign</mat-icon>
            <div class="details-wrapper">
                <h2 class="title">
                    <ng-container *ngIf="chat.source">
                        Because you enjoyed<br><i>{{ chat.source?.title }}</i>
                    </ng-container>
                </h2>
                <p class="subtitle">{{ chat.inprogress ? 'Complete' : 'Start'}} <i>{{ chat.title }}</i></p>
            </div>
        </ion-item>
    </div>  
</ng-container>