<ng-container *ngFor="let category of categories">
    <ion-list lines="full">
        <ion-item 
            class="category-item"
            (click)="clickCategory(category)">
            <ion-label>{{ category.title || category.name }}{{ category.hidden ? ' 🙈' : '' }}</ion-label>
            <ion-icon slot="end" name="chevron-forward-sharp" *ngIf="!isOpened(category)"></ion-icon>
            <ion-icon slot="end" name="chevron-down-sharp" *ngIf="isOpened(category)"></ion-icon>
        </ion-item>
        <ng-container *ngIf="isOpened(category)">
            <ion-item 
                *ngFor="let item of category.conversations"
                class="sub-item" 
                detail="true"
                (click)="clickItem(item)">
                <ion-label class="ion-text-wrap">{{ item.title || item.name }}</ion-label>
            </ion-item>
        </ng-container>
    </ion-list>
</ng-container>
