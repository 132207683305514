import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SharedUserService, SeedService, SharedEventService, ContentService, UtilityService, CheaseedUser } from '@cheaseed/cheaseed-core';
import { BEHAVIOR_CHARGE_IF_UNSTARTED, BEHAVIOR_DOES_NOT_REQUIRE_SEEDS, DEFAULT_CHAT_SEED_COST_KEY, SEEDCOST_CHAT_MESSAGE_KEY } from '@cheaseed/node-utils';
import { AcquireConsumableComponent } from '@cheaseed/ui-core';
import { combineLatest, filter, map, Observable, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatPayGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: SharedUserService,
    private eventService: SharedEventService,
    private contentService: ContentService,
    private utilityService: UtilityService,
    private seedService: SeedService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
      const chatId = route.params.conversationId
      return this.contentService.loader$
      .pipe(
        filter(loaded => !!loaded),
        switchMap(() => this.userService.user$),
        filter(user => !!user),
        switchMap(user => 
          combineLatest([
            this.eventService.lastChatConsumedEventFor(user.docId, chatId),
            this.eventService.lastChatEndedEventFor(user.docId, chatId)
          ])
          .pipe(
            map(([consumedEvents, endedEvents]) => [user, consumedEvents, endedEvents] ))
          ),
        // tap(([user, consumedEvents, endedEvents]) => console.log("in chat_pay guard", user, consumedEvents, endedEvents )),
        map(data => {
          const user:CheaseedUser = data[0]
          const consumedEvents = data[1]
          const endedEvents = data[2]
          // console.log('In ChatPayGuard', consumedEvents, endedEvents)

          if (!this.contentService.isSeedsEnabled())
            return true

          if (chatId) {
            const conv = this.contentService.getConversationNamed(chatId)
            if (!conv)
              return false
            const cost = this.contentService.getChatSeedCost(conv)
            const defaultCost = parseInt(this.contentService.getGlobal(DEFAULT_CHAT_SEED_COST_KEY))
            const consumedEvent = consumedEvents[0]
            const endedEvent = endedEvents[0]
            // console.log('ChatPayGuard evaluating consumed and ended events for', conv, consumedEvent, endedEvent)
            if (consumedEvent) {
              // if chat has ChargeIfUnstarted behavior, check if user has completed last purchase
              if (conv.behaviors?.includes(BEHAVIOR_CHARGE_IF_UNSTARTED) && endedEvent?.createdAt > consumedEvent.createdAt) {
                console.log('ChatPayGuard detected completed, will repurchase', chatId)
              }
              else {
                console.log('ChatPayGuard already paid for', chatId)
                return true
              }
            }
            else if (conv.behaviors?.includes(BEHAVIOR_DOES_NOT_REQUIRE_SEEDS)) {
              // Allow access if chat has behavior DoesNotRequireSeeds
              console.log(`${chatId} DoesNotRequireSeeds`)
              return true
            }

            if (cost > defaultCost && !route.params.confirmed) {
              const msg = this.contentService.getGlobal(SEEDCOST_CHAT_MESSAGE_KEY) || 'Use $cost seeds?'
              this.utilityService.confirm({
                header: msg.replace('$cost', cost),
                confirm: () => {
                  const segs = route.url.map(seg => seg.path)
                  // console.log("ChatPayGuard confirmed", segs, route)
                  this.router.navigate([ ...segs, { confirmed: true } ])
                },
                cancel: () => {
                  this.eventService.recordDeclineChatPay({ chatId, cost })
                  console.log("ChatPayGuard canceled")
                }
              })
              return false // always reject until confirm is clicked
            }

            if (user?.seedBalance >= cost) {
              this.seedService.decrementSeedBalance(cost)
                .then(seeds => {
                  this.eventService.recordSeedsConsumedByChatEvent(
                    {
                      seedsUsed: seeds,
                      chatId: chatId
                    })
                })
              return true
            }
            else {
              this.seedService.promptPurchase$.next(route)
              return false
            }
          }
          else {
            console.log("ChatPayGuard did not find chatId in route params")
            return true
          }
        }))
  }

}
