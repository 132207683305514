<ng-container *ngIf="tab$ | async as tab">
  <ion-segment 
    [value]="tab" 
    mode="md" 
    color="secondary" 
    (ionChange)="segmentChanged($event)" 
    class="page-segment"
    style="margin-bottom: 0">
    <ion-segment-button value="coaching">
      <ion-label>Coaching</ion-label>
    </ion-segment-button>
    <ion-segment-button value="todos">
      <ion-label>To-dos</ion-label>
    </ion-segment-button>
  </ion-segment>
  <ng-container [ngSwitch]="tab">
    <ng-container *ngSwitchCase="'todos'">
      <ion-fab slot="fixed" vertical="top" horizontal="end" [edge]="true">
        <ion-fab-button>
          <ion-icon name="chevron-down-circle"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="bottom">
          <ion-fab-button (click)="filterState$.next('active')">
            Active
          </ion-fab-button>
          <ion-fab-button (click)="filterState$.next('done')">
            Done
          </ion-fab-button>
          <ion-fab-button (click)="filterState$.next('skipped')">
            Skipped
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ng-container *ngIf="filterState$ | async as todostate">
        <ng-container *ngIf="todostate === 'active'">
          <ng-container *ngIf="onboardingService.firstChatCompleted$ | async; else loading">
            <ng-container *ngIf="homeItemService.todoItems$ | async as todos; else loading">
              <ng-container *ngIf="homeItemService.currentLevel$ | async as level">
                <div *ngIf="todos.length > 0 || level > 0" class="level-title-div">
                  <div>
                    <ng-container *ngIf="homeItemService.currentLevelName$ | async as levelName">
                      <div 
                        style="display: flex; align-items: center; font-size: 11px; text-transform: uppercase"
                        (click)="levelClicked(level)">
                          <ion-icon style="font-size: 12px; padding-right: 4px;" name="map-outline"></ion-icon>
                          {{ 'Level ' + level }}
                      </div>
                      <h1>{{ levelName || ('Level ' + level) }}</h1>
                      <ion-progress-bar 
                        [value]="homeItemService.requiredCompletionPercentage$ | async"
                        (click)="homeItemService.advance(true)">
                      </ion-progress-bar>
                    </ng-container>
                  </div>
                <ng-container *ngIf="homeItemService.advanceDisabled$ | async; else advance">
                  <ion-button 
                    class="advance-button" 
                    expand="block" 
                    disabled="true">
                      <cheaseed-global key="advance.button.message" default="Advance"></cheaseed-global>
                  </ion-button>
                </ng-container>
                <ng-template #advance>
                  <ion-button 
                    class="advance-button pulse_me" 
                    expand="block" 
                    (click)="homeItemService.advance()">
                      <ng-container *ngIf="homeItemService.advancing$ | async; else noadvance">
                        <mat-spinner diameter="30" class="save-spinner"></mat-spinner>
                      </ng-container>
                      <ng-template #noadvance>
                        <cheaseed-global key="advance.button.message" default="Advance"></cheaseed-global>
                      </ng-template>
                  </ion-button>
                </ng-template>
              </div>            
              <ng-container *ngIf="todos.length > 0; else emptytodos">
                <cheaseed-home-item 
                  *ngFor="let item of todos; trackBy: utilityService.trackByDocId" 
                  [item]="item"
                  (clicked)="homeItemService.click(item)"
                  (dismissed)="homeItemService.handleDismiss(item)">
                </cheaseed-home-item>
              </ng-container>
              <ng-template #emptytodos>
                <ng-container *ngIf="homeItemService.recommendedItems$ | async as recommendedItems">
                  <cheaseed-instruction-card *ngIf="recommendedItems.length === 0" key="emptytodos.message"></cheaseed-instruction-card>
                </ng-container>
              </ng-template>
              <ng-container *ngIf="homeItemService.recommendedItems$ | async as recommendedItems">
                <ng-container *ngIf="recommendedItems.length > 0">
                  <hr style="border-top: 1px solid rgba(0, 0, 0, 0.2); margin-top: 16px;"/>
                  <div class="level-title-div" style="height: 62px">
                    <div>
                      <h1>Recommended</h1>
                    </div>
                    <ion-button 
                      *ngIf="homeItemService.archiveEnabled$ | async"
                      class="archive-button" 
                      expand="block" 
                      (click)="homeItemService.archive()">
                        <!-- <ion-icon name="caret-forward-circle-outline"></ion-icon> -->
                        <cheaseed-global key="archive.button.message" default="Archive"></cheaseed-global>
                    </ion-button>                  
                  </div>
                  <cheaseed-home-item 
                    *ngFor="let item of recommendedItems; trackBy: utilityService.trackByDocId" 
                    [item]="item"
                    (clicked)="homeItemService.click(item)"
                    (dismissed)="homeItemService.handleDismiss(item)">
                  </cheaseed-home-item>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="todostate === 'skipped'">
          <div class="level-title-div">
            <h1>Skipped Items</h1>
          </div>
          <ng-container *ngIf="homeItemService.skippedItems$ | async as items; else loading">
            <ng-container *ngIf="items.length > 0; else emptyskipped">
              <cheaseed-home-item 
                *ngFor="let item of items; trackBy: utilityService.trackByDocId" 
                [item]="item"
                (clicked)="homeItemService.click(item)"
                (dismissed)="homeItemService.handleDismiss(item)">
              </cheaseed-home-item>
            </ng-container>
            <ng-template #emptyskipped>
              <cheaseed-instruction-card key="emptyskipped.message"></cheaseed-instruction-card>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="todostate === 'done'">
          <div class="level-title-div">
            <h1>Done Items</h1>
          </div>
          <ng-container *ngIf="homeItemService.doneItems$ | async as items; else loading">
            <ng-container *ngIf="items.length > 0; else emptydone"></ng-container>
              <cheaseed-home-item 
                *ngFor="let item of items; trackBy: utilityService.trackByDocId" 
                [item]="item"
                (clicked)="homeItemService.click(item)"
                (dismissed)="homeItemService.handleDismiss(item)">
              </cheaseed-home-item>
          </ng-container>
          <ng-template #emptydone>
            <cheaseed-instruction-card key="emptydone.message"></cheaseed-instruction-card>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'coaching'">
      <ng-container *ngIf="homeItemService.reportItems$ | async as reportItems; else loading">
        <ng-container *ngIf="coachingChats$ | async as items">
          <div class="segment-title">
            <h1>
              <cheaseed-global key="coaching.title.message" default="What would you like to do?"></cheaseed-global> 
            </h1>
          </div>
          <div style="padding-top: 0">
            <cheaseed-home-item 
              *ngFor="let item of items"
              [item]="item"
              [hideSlidingItems]="true"
              (clicked)="homeItemService.click(item)"
              (dismissed)="homeItemService.handleDismiss(item)">
            </cheaseed-home-item>
          </div>
        </ng-container>  
        <ng-container *ngIf="reportItems && reportItems.length > 0">
          <hr style="border-top: 1px solid rgba(0, 0, 0, 0.2); margin-top: 16px;"/>
          <div class="level-title-div" style="height: 62px; padding-bottom: 0; color: var(--chea-purple)">
            <div>
              <h1>Reports</h1>
            </div>
            <ion-button               
              class="archive-button" 
              expand="block"
              routerLink="/tabs/career/reports">
                <cheaseed-global key="explore.reports.viewall.message" default="View All"></cheaseed-global>
            </ion-button>                  
          </div>
          <cheaseed-home-item 
            *ngFor="let item of reportItems; trackBy: utilityService.trackByDocId" 
            [item]="item"
            (clicked)="clickCoachingItem(item)"
            (dismissed)="homeItemService.handleDismiss(item)">
          </cheaseed-home-item>
        </ng-container>  
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <ng-container *ngIf="contentService.globalsLoaded$ | async">
      <cheaseed-labelled-spinner 
        *ngIf="loading" 
        title="Did You Know?">
      </cheaseed-labelled-spinner>    
    </ng-container>
  </ng-template>

<ion-modal #firstTimeModal
  *ngIf="isFirstTimeInLevels$ | async as enabled"
  [isOpen]="enabled && tab === 'todos'" 
  [canDismiss]="true"
  (didDismiss)="homeItemService.dismissFirstTimeAlert()">
  <ng-template>
    <ion-content scrollY="false" (click)="firstTimeModal.dismiss()">
      <div class="alert-modal">
        <div class="close-button">
          <ion-icon name="close"></ion-icon>
        </div>
        <div class="content">
          <cheaseed-global key="level1.alert.message"></cheaseed-global>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

</ng-container>

<ion-modal #advanceModal
  *ngIf="homeItemService.isFirstTimeAdvanceEnabled$ | async as enabled"
  [isOpen]="enabled"  
  [canDismiss]="true"
  (didDismiss)="homeItemService.dismissAdvanceAlert()">
  <ng-template>
    <ion-content scrollY="false" (click)="advanceModal.dismiss()">
      <div class="alert-modal">
        <div class="close-button">
          <ion-icon name="close"></ion-icon>
        </div>
        <div class="content">
          <cheaseed-global key="level1advance.alert.message"></cheaseed-global>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #levelsModal
  *ngIf="levelClicked$ | async as levelClicked"
  [isOpen]="!!levelClicked"
  [canDismiss]="true"
  (didDismiss)="levelDismissed()">
  <ng-template>
    <ion-content (click)="levelsModal.dismiss()">
      <div class="levels-modal">
        <div class="close-button">
          <ion-icon name="close"></ion-icon>
        </div>
        <div class="title">Level Map</div>
        <div class="list" *ngFor="let item of levelNames">
          <span [class]="(item.key === '' + levelClicked) ? 'selected' : ''">{{ item.key + ". " + item.value }}</span>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
