import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService, SharedUserService, UtilityService } from "@cheaseed/cheaseed-core"
import { Entry, EntrySpec } from '@cheaseed/node-utils';

@Component({
  selector: 'cheaseed-entry-card',
  templateUrl: './entry-card.component.html',
  styleUrls: ['./entry-card.component.scss'],
})
export class EntryCardComponent implements OnInit {

  @Input() entry:Entry = new Entry({})
  @Input() entrySpec:EntrySpec = new EntrySpec({})
  @Input() extraActions:any[] = [];
  @Input() childCounts:any = {};
  @Input() favorites:Map<string, any> = new Map()
  @Input() promptRemoveFavorite = false;
  @Input() entryService:any;
  @Input() quickAddService:any;
  displayType = ""
  @Input() collectionCounts:any = {}
  favoritable = false;
  collectible = false;

  constructor(
    private router: Router,
    private utilityService: UtilityService,
    public userService: SharedUserService,
    private contentService: ContentService)
  { }

  ngOnInit() {
    // console.log("EntryCardComponent ngOnInit for", this.entry)
    this.favoritable = this.entrySpec.isFavoritable()
    this.collectible = this.entrySpec.isCollectible()
    this.displayType = this.contentService.getAction(this.entry.subtype)?.name || this.contentService.getSingularGlobal(this.entry.type as string)
  }

  async confirmDelete() {
    await this.utilityService.confirm({
      header: this.contentService.getGlobal('deleteEntry.message'),
      confirm: async () => {
        await this.entryService.deleteEntryLite(this.entry)
      }
    })
  }

  gotoEntryForm(params:any) {
    this.router.navigate([ 'sectionentryform', params ])
  }

  async viewEntry() {
    if (!this.entry.isUnapproved())
      this.router.navigate([ 'entryview', { entryId: this.entry.docId } ])
    else {
      await this.utilityService.notify({
        message: this.contentService.getGlobal('unapproved.alert.message') ||
          'This entry is not yet approved for viewing.',
        })    
    }
  }

  editEntry() {
    this.gotoEntryForm({ entryId: this.entry.docId })
  }

  addToFavorites() {
    this.entryService.addToFavorites(this.entry)
  }

  async removeFromFavorites() {
    if (this.promptRemoveFavorite)
      await this.confirmRemoveFavorite()
    else {
      this.entryService.removeFromFavorites(this.entry)
    }
  }

  async toggleFavorite() {
    if (this.favorites.has(this.entry.docId))
      this.removeFromFavorites()
    else
      this.addToFavorites()
  }

  async confirmRemoveFavorite() {
    await this.utilityService.confirm({
      header: "Are you sure you want to remove this favorite?",
      confirm: async () => {
        this.entryService.removeFromFavorites(this.entry)
      }
    })
  }

  addToCollection() {
    this.quickAddService.quickAddToCollection(this.entry.displayName, this.entry.docId)
  }
  
  clickAction(action:any) {
    this.gotoEntryForm({ entrySpecId: action.actionEntrySpec.name, parentEntryId: this.entry.docId })
  }

  async shareEntry() {
    this.router.navigate( [ 'share-entry-tile', this.entry.docId ])
    // await this.utilityService.notify({
    //   header: "Share",
    //   message: "Coming soon. Good things come to those who wait."
    // })
  }

  async clickExtraAction(action:any) {
    const docId = action.getParam ? this.entry.attributes[action.getParam] : this.entry.docId
    if (this.entry.type === "Goal") {
      const params = Object.assign({}, action.args)
      params['defaults'] = JSON.stringify([{ goal: docId }])
      this.router.navigate([ action.route, params ])
    }
    else if (docId && action.route)
      this.router.navigate([ action.route, { entryId: docId }])
    else if (docId && action.click) 
      action.click(this.entry)
    else {
      const thing:string = action.getParam.charAt(0).toUpperCase() + action.getParam.slice(1)
      await this.utilityService.notify({
        message: `There is no ${thing} associated with this ${this.entry.type}`
      })
    }
  }
}
