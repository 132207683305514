<form *ngIf="!loading">
    <ng-container *ngFor="let block of inputBlocks">
      <ion-card *ngIf="!block.hidden" lines="full" class="ion-no-padding">
        <ion-card-header class="ion-no-padding">
            <!-- <div style="display: flex; justify-content: space-between;">
                <h1>{{ block.name }}</h1>
                <ion-icon name="chevron-forward-sharp"></ion-icon>
            </div> -->
            <ion-grid class="block" (click)="block.state = !block.state">
              <ion-row>
                <ion-col size="9">
                  <ion-card-subtitle color="{{ block.state ? 'secondary' : 'primary' }}">
                    {{ block.name || "Details" }}
                  </ion-card-subtitle>
                </ion-col>
                <ion-col size="3">
                    <ion-icon style="font-size: 20px; float:right" name="{{ block.state ? 'chevron-down-sharp' : 'chevron-forward-sharp' }}"></ion-icon>  
                </ion-col>
              </ion-row>
            </ion-grid>
        </ion-card-header>
        <ion-card-content class="ion-no-padding ion-padding-bottom" *ngIf="block.state">
          <ng-container *ngFor="let a of block.attributes">
            <ion-grid *ngIf="!attributeMap.get(a.attributeName).hidden" fixed>
                <ion-row>
                  <ion-col size="12">
                    <cheaseed-entry-field [form]="entryFormGroup" [attrib]="a" (fieldChanged)="fieldChanged($event)"></cheaseed-entry-field>
                  </ion-col>
                </ion-row>
              </ion-grid>       
          </ng-container>
        </ion-card-content>
      </ion-card>
    </ng-container>
  </form>
  