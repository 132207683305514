<div *ngIf="series" class="dialog">
  You are in the series:
  <h1 class="title">{{ series.title }} </h1>
  <p style="text-align: left;">{{ series.description }}</p>
  <!-- <p class="next-header">Next up:</p> -->
  <cheaseed-series-chat-list [chats]="series.conversations" [current]="chat"></cheaseed-series-chat-list>
  <ion-button class="ok" (click)="dismiss()" color="secondary" style="font-weight: bold;">
    <cheaseed-global key="seriesintro.start.button" default="Start"></cheaseed-global>
  </ion-button>
</div>
