import { Component, OnInit, NgZone, Inject, inject } from '@angular/core'
import { ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router } from '@angular/router'
import { ModalController, Platform } from '@ionic/angular'
import { App, AppState } from '@capacitor/app'
import { Network } from '@capacitor/network'
import { Subscription, BehaviorSubject, timer, filter, take, withLatestFrom, firstValueFrom, combineLatestWith, map } from 'rxjs'
import { UsertoolsService } from './services/usertools.service'
import { PushNotificationPayload, PushNotificationsService } from './services/push-notifications.service'
import {
  ContentService,
  UtilityService,
  SharedEventService,
  SharedUserService,
  OnboardingService,
  ProgramService,
  EntryService,
  SharedMessageService,
  ChatQueueService,
  CleverTapService,
  AbstractPage,
  AuthService,
  SharedChallengeService,
  FirebaseService,
  CheaseedUser,
  PurchaseService,
  SeedService,
  OffersService,
  DeepLinkService,
  AudioService,
  CheaseedStripeService} from '@cheaseed/cheaseed-core'
import { environment } from '../environments/environment'
import { SplashScreen } from '@capacitor/splash-screen'
import { Browser } from '@capacitor/browser'
import { Preferences } from '@capacitor/preferences'
import { AcquireConsumableComponent } from '@cheaseed/ui-core'
import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links'
import { YYYYMMDDHHmm_FORMAT, formatDate, retry } from '@cheaseed/node-utils'
import { ChatStateService } from '@cheaseed/cheaseed-core'

declare global {
  interface Window {
    cypressNavigate: (route: unknown[]) => void;
    Cypress: boolean;
    AdvancedPage: AbstractPage
  }
}
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // @HostBinding('@.disabled')

  userService = inject(SharedUserService)
  contentService = inject(ContentService)
  modalController = inject(ModalController)
  stripeService = inject(CheaseedStripeService)
  isCapacitor = false
  contentSubscription: Subscription
  networkOnline = true
  contentTimestamp: string
  private backButtonClickedSubject = new BehaviorSubject<boolean>(false)
  public backButtonClicked$ = this.backButtonClickedSubject.asObservable()
    
  pages = [
    { title: 'Home', url: '/tabs/home', icon: 'home' },
    // { title: 'Invite a Friend', url: '/invite-friend', icon: 'person-add' },
    { title: 'Give Feedback', url: '/conversation/UserFeedbackIteration24' },
    { title: 'Profile', url: 'profile' },
    { title: 'Career Plan', url: 'reflection' },
    { title: 'Privacy Policy', handler: () => this.openPrivacy() },
    { title: 'Get Seeds', handler: () => this.getSeeds() },
    { title: 'Redeem Bonus Code', handler: () => this.redeemOfferCode() },
    { title: 'Frequently Asked Questions', handler: () => this.openFaq() },
    { title: 'Sign Out', handler: () => this.signOut() },
    { title: 'Toggle Admin Role', handler: () => this.toggleAdmin(), isAdmin: true },
    { title: 'Admin Tools', url: '/advanced', role: 'admin' },
    { title: 'All Chats', url: ['/chat-search'], role: 'admin' },
    { title: 'Export', handler: () => this.exportUserData(), role: 'admin' },
    { title: 'Preview App Update Page', url: ['/force-app-update'], role: 'admin' },
  ]
  networkStatusAlert: HTMLIonAlertElement | null = null

  authorizedPages$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      combineLatestWith(this.userService.role$),
      map(([, role]) => {
        return this.pages.filter(p => (
          !p.isAdmin && !p.role) ||
          (p.isAdmin && this.userService.hasAdminClaim()) ||
          (p.role === 'admin' && role === 'admin') ||
          p.role === 'user')
      }))

  appInfo$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      combineLatestWith(this.contentService.contentConfigTimestamp$),
      map(([user, secs]) => {
        return {
          user,
          environment: this.environment,
          contentTimestamp: formatDate(secs * 1000, YYYYMMDDHHmm_FORMAT)
        }
      })
    )
         
  toggleAdmin() {
    this.userService.toggleRole$.next(true)
  }

  async getSeeds() {
    this.seedService.promptPurchase$.next(null)
  }

  async redeemOfferCode() {
    await this.offersService.launchRedeemOfferCodeDialog()
  }

  constructor(
    @Inject('environment') private environment: any,
    private platform: Platform,
    private router: Router,
    private auth: AuthService,
    private eventService: SharedEventService,
    private audioService: AudioService,
    private userToolsService: UsertoolsService,
    private chatQueueService: ChatQueueService,
    private messageService: SharedMessageService,
    private onboardingService: OnboardingService,
    private entryService: EntryService,
    private firebase: FirebaseService,
    private programService: ProgramService,
    private challengeService: SharedChallengeService,
    private utilityService: UtilityService,
    private pushNotificationService: PushNotificationsService,
    private zone: NgZone,
    private clevertap: CleverTapService,
    private purchaseService: PurchaseService,
    private seedService: SeedService,
    private offersService: OffersService,
    private deeplinkService: DeepLinkService,
    private chatStateService: ChatStateService) {
    
      this.initializeApp()

      // Method Cypress will call
      if (window.Cypress) {
        window.cypressNavigate = (route: unknown[]) => this.cypressNavigate(route);
      }
    }

  public cypressNavigate(route: any[]) {
    this.zone.run(() => {
      this.router.navigate(route);
    });
  }
  // From: https://capacitor.ionicframework.com/docs/guides/deep-links/  
  // In order for Apple and Google to permit deep links to open your app, 
  // a two-way association between your website and app must be created. 
  // One file for each must be created and placed within a .well-known folder on your website, like so: https://beerswift.app/.well-known/.

  // Continue on for iOS and Android configuration details.
  // TODO: https://stackoverflow.com/questions/66285483/ionic-capacitor-android-splash-screen-responsiveness

  initializeApp() {
    this.deeplinkService.clearDeepLinks()
    // Detect page navigations
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd || e instanceof NavigationStart))
      .subscribe(e => {
        // console.log( "NavigationEnd", e)
        if (e instanceof NavigationEnd)
          this.eventService.recordPageNavigation({ page: e.url })
        else if (e instanceof NavigationStart)
          this.backButtonClickedSubject.next(!!e.restoredState)
      })

    this.platform.ready().then(async () => {
      
      this.isCapacitor = this.platform.is('capacitor')
      // Network status
      Network.getStatus()
        .then(async status => await this.updateNetworkStatus(status.connected))
      Network.addListener('networkStatusChange', async status => {
        // console.log('in networkStatusChange listener')
        if (status.connected && this.networkStatusAlert) {
          // console.log('networkStatusChange to dismiss alert')
          this.networkStatusAlert.dismiss()
          this.networkStatusAlert = null
        }
        await this.updateNetworkStatus(status.connected)
      })

      if (this.isCapacitor) {
        /* remove crashlytics for now as it is causing issues in uploading to
           TestFlight 
        // Set up Crashlytics
        // set the context to use for crashes and reported exceptions
        await FirebaseCrashlytics.setContext({ key: 'build', value: this.environment.buildTag, type: 'string' })
        await FirebaseCrashlytics.setContext({ key: 'release', value: this.environment.releaseTag, type: 'string' })

        // If this app startup is following a crash, send crash data to Crashlytics
        if (await FirebaseCrashlytics.didCrashDuringPreviousExecution()) {
          await FirebaseCrashlytics.sendUnsentReports()
        }
        */
        BranchDeepLinks.addListener('init', async (event: BranchInitEvent) => {
          // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
          // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
          // initialize deeplink only if not already set. We sometimes get
          // spurious Branch init events with referringParams = {} which causes us to lose
          // the params
          if (Object.keys(event.referringParams || {}).length !== 0) {
            // TODO: Check value of +clicked_branch_link after having clicked a web_only link
            const clicked_branch_link = event.referringParams['+clicked_branch_link']
            // console.log(`IN BranchDeepLink init, event = ${JSON.stringify(event)}`)
            if (clicked_branch_link) {
              this.deeplinkService.setDeeplinkParams(event.referringParams)
              // check if user is logged in
              if (this.auth.isLoggedIn() && this.chatQueueService.isFirstChatCompleted()) {
                this.deeplinkService.checkLinkParams(event.referringParams)
              }
            }
          }
        })
        BranchDeepLinks.addListener('initError', (error: any) => {
          console.error(`BranchDeepLink initError: ${JSON.stringify(error)}`)
        })
      }
      timer(1000).subscribe(() =>
        SplashScreen.hide()
      )

      // Handle app leaving foreground, close or inactivated
      App.addListener('appStateChange', async (state: AppState) => {
        // state.isActive contains the active state
        // console.log('appStateChange', state.isActive)
        if (state.isActive) {
          this.eventService.recordAppActivated()
        }
        else {
          // this shouldnt get triggered after the user logs out and we transition
          // to the login page
          if (this.userService.getCurrentUser()) {
            this.userService.finalize()
            await this.chatQueueService.writeUserStats()
            this.eventService.recordAppDeactivated()
          }
        }
      });

      console.log(`Platforms: ${JSON.stringify(this.platform.platforms())}`)
      // console.log(`Cordova: ${this.platform.is("cordova")}`)
      // console.log(`Capacitor: ${this.isCapacitor}`)
      // console.log(`ios: ${this.platform.is("ios")}`)
      // console.log(`android: ${this.platform.is("android")}`)
      // console.log(`desktop: ${this.platform.is("desktop")}`)
      // console.log(`hybrid: ${this.platform.is("hybrid")}`)
      console.log(`dimensions: ${this.platform.width()} x ${this.platform.height()}`)
    })
  }

  async updateNetworkStatus(connected: boolean) {
    this.networkOnline = connected
    console.log('networkStatusChange detected', this.networkOnline)
    if (!this.networkOnline) {
      this.networkStatusAlert = await this.utilityService.notify({
        header: 'No network connection',
        message: 'Please check your network connection and try again.'
      })
    }
  }

  async ngOnInit() {
    // console.log("App ngOnInit")
    // Hardware back button on android handling - exit the app. Has no effect on iOS and in the web app
    this.platform.backButton.subscribeWithPriority(100, async () => {
      await App.exitApp()
    })
    // initialize clevertap web sdk
    this.clevertap.init(environment.clevertap.accountID, environment.clevertap.passCode)

    // check to load bundled content
    // only load if local storage is empty or older than timestamp of bundled content
    await this.contentService.checkBundledContent()

    if (this.networkOnline) {
      // a little dicey here, because we may not set again if network is reestablished
      await this.contentService.getContentConfigTimestamp()
    }
    else
      this.updateNetworkStatus(this.networkOnline)

    // Pre-load Globals
    await this.contentService.loadGlobals()

    // Handle successful login
    // this.contentService.isLoaded$
    //   .pipe(
    //     distinctUntilChanged(),
    //     switchMap(() => this.userService.userLoadStatus$),
    //     tap(status => console.log(`userLoadStatus$ received ${status}`)),
    //     withLatestFrom(this.userService.user$),
    //     filter(([ status, user ]) => !!status && !!user && ['exists', 'created'].includes(status)))
    //   .subscribe(([ status, user ]) => {
    //       this.initializeSession(status, user)
    //   })
    this.userService.userLoadStatus$
      .pipe(
        withLatestFrom(this.userService.user$, this.contentService.loader$),
        filter(([status, user, ]) => !!status && !!user))
      .subscribe(([status, user, loaded]) => {
        if (['exists', 'created'].includes(status)) {
          console.log("App received userLoadStatus", status, user, loaded)
          this.initializeSession(status, user, loaded)
        }
      })      
    this.seedService.promptPurchase$
      .pipe(filter(route => route !== undefined))
      .subscribe(async (route: ActivatedRouteSnapshot) => {
        await this.launchPurchaseModal(AcquireConsumableComponent, 
          {
            title: "Acquire Seeds",
            offers: await this.seedService.getOffers(),
            route: route
          })
      })
  }

  async initializeSession(status: string, user: CheaseedUser, contentLoaded = true) {

    console.log('initializeSession', contentLoaded)
    // 2024-01-17 Commented out imperative load, see ContentService constructor
    // separate error handling for content loading so that it is easier
    // to track. retry 2 times in case of failure the first time around
    try {
      if (!contentLoaded) {
        console.log('initializeSession','Loading resources using retry')
        await retry((flag: boolean) => this.contentService.reload(flag), [true], 2)
        this.contentService.subscribeToContentConfig()
      }
    }
    catch (err) {
      console.error(`Error loading content for user ${user}`, err)
      this.utilityService.notify({
        header: "Unable to load content",
        message: `${err.message}`,
        cancel: async () => {
          await this.signOut()
        }
      })
      throw err // pass to global error handler
    }

    try {
      this.audioService.initialize()
      this.entryService.reinitialize()
      this.messageService.initialize()
      this.programService.initialize()
      this.challengeService.initialize()
      // this.learnService.initialize()
      // this.statsFeedService.initialize()
      this.onboardingService.initialize()
      this.offersService.initialized$.next(true)
      if (status === 'created') {
        this.userService.initializeDefaultAttributeSpecs()
        // this.contentService.getFirstAuthTaskSets()
        //   .forEach(ts => this.taskSchedulerService.scheduleTaskSet(ts))
        let inviter
        const deeplink = await firstValueFrom(this.deeplinkService.deeplinkParams$)
        if (deeplink) {
          // If user clicked a branch invitation, add the invitedBy field to the user
          inviter = this.deeplinkService.getInviter(deeplink)
          if (inviter) {
            this.userService.setInvitedBy(inviter)
            console.log(`inviter is`, inviter)
          }
        }
        // Record user creation, including invitedBy
        this.eventService.recordUserCreation({ ...user, inviter })
      }
      else
        this.eventService.setProfile({
          name: user.name,
          provider: user.provider,
          isInternalUser: !!this.userService.hasAdminClaim()
        })

      // Push notifications must be registered after user is logged in
      if (this.isCapacitor) {
        await this.pushNotificationService.registerPushNotifications()
      }

      await this.seedService.initialize()
      this.stripeService.initialize(this.modalController)
      // TODO - use cloud functions to sync delayed payment transactions
      // For now, assume delayed transactions will succeed and grant seeds for them
      // await this.seedService.syncToRevenueCat()

      await this.chatStateService.initialize(user.docId) // must be completed before chatQueueService observables are initialized
      this.chatQueueService.loadUserEvents()
      this.chatQueueService.initializeKeyObservables()

      // If first time, queue up chats and launch onboarding chat
      if (status === 'created') {
        // Note that if there is a $deeplink_path, we will leave the deeplinkParams intact 
        // and process when we eventually display Home
        this.userService.isFirstVisitToday()
      }

      // 20220216 Must now add approved: true to production Firestore content/config when app becomes approved
      if (this.environment.releaseTag !== this.contentService.latestReleaseTag
        && (!environment.production || this.contentService.approvedInAppStores)) {
        console.log(`Current release tag ${this.environment.releaseTag} does not match latest release ${this.contentService.latestReleaseTag}`)
        // Route to a dead-end page that prompts user to update the app, provides link to app store
        this.router.navigate(['force-app-update'])
        // await this.promptToUpdateApp()
      }

      // Handle followup from completing first chat, as well deeplink user initialization well after first chat
      this.chatQueueService.conversationStatus$
        .pipe(
          filter(map => !!map),
          take(2),   // only subscribe to the first non-null value
          withLatestFrom(this.deeplinkService.deeplinkParams$))
        .subscribe(([, params]) => {
          // check if firstchat has been completed
          // console.log("conversationStatus$ loaded size", map.size)
          if (!this.chatQueueService.isFirstChatCompleted())
            this.chatQueueService.launchFirstChat()
          else if (params)
            this.deeplinkService.checkLinkParams(params)
        })

      // do this after everything else is initialized
      // It is still possible to lose the routing if forceAppUpdate occurs above
      // Since that is an extreme edge case, we ignore it for now
      if (this.isCapacitor) {
        await this.checkAndroidNotificationStart()  // may route away from Home
      }
    }
    catch (err) {
      console.error(`Error initializing user ${user}`)
      this.utilityService.notify({
        header: "Unable to launch application",
        message: `${err.message}`,
        cancel: async () => {
          await this.signOut()
        }
      })
      throw err // pass to global error handler
    }
  }

  clickSection2(f: any) {
    if (f.sections) {
      f.opened = !f.opened
    }
    else if (f.click) {
      f.click()
    }
  }

  async signOut() {
    if (this.platform.is('capacitor'))
      await this.purchaseService.logOut()
    await this.router.navigate(['login'])
  }

  // Called by Login page 
  // See https://github.com/angular/angularfire/issues/1459
  async signOutUser() {
    if (this.auth.isLoggedIn()) {
      console.log("signing out")
      // Commented out for Beta 44
      //if(this.platform.is('capacitor'))
      //  await this.purchaseService.logOut()
      this.unsubscribe()
      await this.auth.logout()
    }
  }

  async openPrivacy() {
    await this.openExternal(this.contentService.getGlobal('privacypolicy.url'))
  }

  async openFaq() {
    await this.openExternal(this.contentService.getGlobal('faqs.url'))
  }

  async openExternal(url: string) {
    if (this.isCapacitor)
      await Browser.open({ url: url });
    else
      window.open(url, '_blank')
  }

  async promptToUpdateApp() {
    await this.utilityService.confirm({
      header: this.contentService.getGlobal('newversionalert.title') || "New version available",
      message: this.contentService.getGlobal('newversionalert.message') || `Do you want to update your app?`,
      cancel: () => { },
      confirm: () => {
        const url = this.contentService.getAppUpdateURL()
        if (url)
          window.open(url)
      }
    })
  }

  async exportUserData() {
    const email = this.userService.getCurrentUserId()
    const u = await this.userToolsService.extractUser(email)
    await this.utilityService.confirm({
      header: `Do you want chea seed to send your data to ${email}?`,
      confirm: async () => {
        this.firebase.callCloudFunction("sendEmailAttachment",
          {
            to: email,
            subject: 'Your chea seed data',
            text: 'The attachment contains your chea seed data.',
            attachmentContents: JSON.stringify(u, null, 2),
            attachmentFilename: 'YourCheaSeedData.json'
          }).subscribe(result => console.log(result))
      }
    })
  }

  unsubscribe() {
    if (this.auth.isLoggedIn()) {
      this.eventService.recordSignOut()
      this.contentSubscription?.unsubscribe()
      this.contentSubscription = null
      this.entryService.unsubscribe()
      this.contentService.unpublishLoaded()
    }
  }

  // On android, if the app is killed or the phone restarted while a push notification
  // has been received but not yet clicked on, clicking on the notification afterwards
  // lauches the app but does not go to the desired page. See Issue #1615. We have
  // implemented a workaround wherein the necessary information is captured in
  // MainActivity.java and stored as a SharedPreference. Once the app is initialized
  // we use the information to navigate to the correct page
  async checkAndroidNotificationStart() {
    if (this.platform.is('android')) {
      // Every notification should have a type
      // if this was a normal launch, there will be no type stored as a shared preference
      await Preferences.configure({ group: 'CapacitorStorage' })
      const type = (await Preferences.get({ key: "cheaseed_notification_type" })).value
      if (type) {
        const payload: PushNotificationPayload = {
          type: type,
          id: (await Preferences.get({ key: "cheaseed_notification_id" })).value,
          title: (await Preferences.get({ key: "cheaseed_notification_nt" })).value,
          message: (await Preferences.get({ key: "cheaseed_notification_nm" })).value,
          challengeDocId: (await Preferences.get({ key: "cheaseed_notification_challengeDocId" })).value,
          challengeOwnerId: (await Preferences.get({ key: "cheaseed_notification_challengeOwnerId" })).value
        }

        // Remove the notification from Storage
        await Preferences.remove({ key: "cheaseed_notification_id" })
        await Preferences.remove({ key: "cheaseed_notification_type" })
        await Preferences.remove({ key: "cheaseed_notification_challengeDocId" })
        await Preferences.remove({ key: "cheaseed_notification_challengeOwnerId" })
        await Preferences.remove({ key: "cheaseed_notification_nm" })
        await Preferences.remove({ key: "cheaseed_notification_nt" })

        // Process the notification payload
        await this.pushNotificationService.actionAfterNotificationTapped(payload, true)
      }
    }
  }

  getPlural(key: string) {
    return this.contentService.getPluralGlobal(key)
  }

  async launchPurchaseModal(componentClass: any, componentProps: any) {
    console.log('Component Props', componentProps)
    const modal = await this.modalController.create({
      component: componentClass,
      componentProps: componentProps
    })
    return await modal.present();
  }
}