import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedChallengeService, UtilityService } from '@cheaseed/cheaseed-core';
import { Challenge, nowstr, toDate as toDateFn } from '@cheaseed/node-utils';
@Component({
  selector: 'cheaseed-challenge-list',
  templateUrl: './challenge-list.component.html',
  styleUrls: ['./challenge-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChallengeListComponent implements OnInit {

  @Input() challenges: Challenge[] = []
  @Input() completed = false
  todaysDate = nowstr()
  // listTitle = this.completed ? 'Completed Challenges' : 'My Challenges'

  constructor(
    private router: Router,
    public utilityService: UtilityService,
    public challengeService: SharedChallengeService
  ) { }

  ngOnInit(): void {
  }

  clickChallenge(item: Challenge) {
    this.router.navigate([ 'challenge-view', item.docId ])
  }

  shareChallenge(item: Challenge) {
    console.log("shareChallenge", item)
  }

}
