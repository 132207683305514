import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'cheaseed-mylist-feed',
  templateUrl: './mylist-feed.component.html',
  styleUrls: ['./mylist-feed.component.scss']
})
export class MylistFeedComponent implements OnInit {
  playlist$: Observable<any> = of(null)

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {}

  click(chat: any) {
    this.router.navigate( ['conversation', chat.name, { backLabel: 'Home', launchSource: "Home" } ] )
  }

}
