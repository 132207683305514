import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SharedUserService, SeedService, ContentService } from '@cheaseed/cheaseed-core';
import { AcquireConsumableComponent } from '@cheaseed/ui-core';
import { filter, map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChallengePayGuard implements CanActivate {

    constructor(
        private userService: SharedUserService,
        private seedService: SeedService,
        private contentService: ContentService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.userService.user$
            .pipe(
                filter(user => !!user),
                map(user => {
                    //TODO - for release 45, restrict the feature to admin users
                    if (!this.contentService.isSeedsEnabled())
                        return true
                    console.log("ChallengePayGuard route.params", route.params)
                    if (user?.seedBalance as number >= this.contentService.getChallengeSeedCost()) {
                        return true
                    }
                    else {
                        this.seedService.promptPurchase$.next(route)
                        return false
                    }
                }))
    }

}
