import { Injectable } from '@angular/core'
import { ChatQueueService, FirebaseService, SharedUserService } from '@cheaseed/cheaseed-core'
import { collectionData, DocumentData, setDoc } from '@angular/fire/firestore'

@Injectable({
  providedIn: 'root'
})
export class UsertoolsService {

  constructor(
    private userService: SharedUserService,
    private chatQueueService: ChatQueueService,
    private firebase: FirebaseService) { }

  // Extract/Restore

  async extractAllUsers() {
    const userDocsRef = this.userService.getUsersCollectionRef()
    //TODO - change to observable
    const userDocData: DocumentData[] = await collectionData(userDocsRef, {idField: 'id'}).toPromise()
    const result = []
    for (const doc of userDocData) {
      const extract = await this.extractUser(doc.id)
      result.push(extract)
    }
    return result
  }

  async restoreAllUsers(data) {
    console.log("restoreAllUsers", data)
    for (const u of data) {
      await this.restoreUser(u)
    }
  }

  async restoreUser(user) {
    console.log("restoreUser", user)
  }

  async extractUser(email: string) {
    console.log("extractUser", email)
  }
}
