<ion-card class="chat-card">
  <ion-grid>
    <ion-row class="title-row">
      <ion-col size="12">
        <ion-card-title>{{ chat.title || "NEEDS TITLE" }}</ion-card-title>
      </ion-col>
    </ion-row>
    <ion-row class="subtitle-row">
      <ion-col size="12">
        <ion-card-subtitle>{{ chat.subtitle || "NEEDS SUBTITLE" }}</ion-card-subtitle>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showSeries && series" class="series-row">
      <ion-col size="12">
        {{ series.title || series.name }}
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showSeries && series" class="series-progress-row">
      <ng-container *ngIf="chatQueueService.seriesCompletionStatus$ | async as statuses">
        <ion-col size="12" *ngIf="statuses[series.name] as status">
        <ion-progress-bar 
          style="--progress-background: {{ topic?.color }}; --buffer-background: {{ topic?.altColor }};"
          [value]="status?.result || 0.0">
        </ion-progress-bar>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-grid>
</ion-card>