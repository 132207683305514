import { Component, Input } from '@angular/core';
import { OnboardingFeedState, OnboardingService } from '@cheaseed/cheaseed-core';
import { Router } from '@angular/router';
@Component({
  selector: 'cheaseed-onboarding-feed',
  templateUrl: './onboarding-feed.component.html',
  styleUrls: ['./onboarding-feed.component.scss']
})
export class OnboardingFeedComponent {

  @Input() state: OnboardingFeedState = { // dummy default
      onboardingSeries: null,
      totalSteps: 1
  }

  constructor(
    private router: Router,
    private onboardingService: OnboardingService 
  ) { }

  click() {
    if (this.state?.nextChat)
      this.router.navigate([ '/conversation', this.state.nextChat.name ])
    else if (this.state)
      this.onboardingService.completeOnboarding()
  }
}
